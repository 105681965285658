import React from 'react';
import "../styles/InfoCard.css"
import tick from "../media/iconTick.png"
import cross from "../media/iconCross.png"
import { getDateString } from '../functions/DateString';

class DoctorCard extends React.Component {

    constructor(props) {
        super(props);
        // this.dateObj = this.props.details.doctorData.dateApproved;
        this.date = (this.props.details.doctorData.dateApproved) ? getDateString(this.props.details.doctorData.dateApproved) : "-";
        
        this.approvedOrRejected = null; 
        switch (this.props.status) {
            case "verified": 
                this.approvedOrRejected = "Approved";
                break;
            case "rejected": 
                this.approvedOrRejected = "Rejected";
                break;
            case "pending": 
                this.approvedOrRejected = "Applied";
                break;
            default: 
                break;
        }

        this.doctorType = null;
        switch (this.props.details.doctorData.type) {
            case "gp": 
                this.doctorType = "GP";
                break;
            case "sp": 
                this.doctorType = "Specialist";
                break;
            default: 
                this.doctorType = "GP";
                break;
        }
            
    }

    render() {
        return (
            <div className='admin-card-container flex-horizontal' key={this.props.details._partition}>

                <div>
                    <h3>{this.props.details.name}</h3>
                    <p>{this.doctorType} at {this.props.details.doctorData.clinic}</p>
                    <p><b>Mobile </b>{this.props.details.doctorData.phoneNumber}</p>
                    <p><b>Email </b>{this.props.details.doctorData.email}</p>
                    <p><b>MCR No. </b>{this.props.details.doctorData.MRN}</p>
                    {/* {this.props.isVerified ? <p><b>Patients </b>{this.props.details.doctorData.patients}</p> : null} */}
                    {(this.props.status !== "pending") ? <p><b>{this.approvedOrRejected} by </b>{this.props.details.users_approvedBy ? this.props.details.users_approvedBy.name : "-"} </p> : null}
                    <p><b>{this.approvedOrRejected} on </b> {this.date} </p>
                    {/* {(this.props.status === "reject") ? <p><b>Rejected </b>by {this.props.details.doctorData.approvedBy} on {this.date} </p> : null} */}
                </div>

                <div>
                    {(this.props.status !== "verified") 
                        ? <img src={tick} onClick={() => this.props.approve(this.props.details._partition)} className='Button-round inline' style={{backgroundColor: "var(--green)"}}/>
                        : null}
                    {(this.props.status !== "rejected") 
                        ? <img src={cross} onClick={() => this.props.reject(this.props.details._partition)} className='Button-round inline' style={{backgroundColor: "var(--red)"}}/>
                        : null}
                </div>
                        
            </div>
        )
    }

}
export default DoctorCard