import React, { useState, useEffect } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { v4 as uuid } from 'uuid';
import { getAllMedicationData, getAllSessions, getPatientAAP, updatePatientAAP } from '../config/FirebaseConfig';
import Select from "react-select";
import '../styles/AAPPage.css';
import { getDateString } from '../functions/DateString';
import NotFoundPage from './NotFoundPage';
import loading from '../media/loading.gif';

const AAPPage = (props) => {

    let { patientId }  = useParams();
    patientId = patientId.toString();
    // console.log(patientId);s

    const [isLoading, setIsLoading] = useState(true); 
    const [isSaving, setIsSaving] = useState(false); 
    const [medOptions, setMedOptions] = useState({}); 
    const [medData, setMedData] = useState({}); 
    const [metadata, setMetadata] = useState({}); 

    // const [AAPData, setAAPData] = useState({}); 


    const [green, setGreen] = useState(
        {
        preventer: {
            medicationId: null,
            name: null, 
            category: null,
            concentration: null,
            concentrationUnit: null, 
            doseValue: null,
            doseUnit: null, 
            frequency: null,
        },
        reliever: {
            medicationId: null,
            name: null, 
            category: null,
            concentration: null,
            concentrationUnit: null, 
            doseValue: null,
            doseUnit: null, 
            frequency: null,
        },
        others: [],
        beforeExercise: {
            medicationId: null,
            name: null,
            category: null,
            concentration: null,
            concentrationUnit: null,
            doseValue: null,
            doseUnit: null,
            frequency: null,
        },
        }
    );
    const [yellow, setYellow] = useState({
        preventer: {
            medicationId: null,
            name: null, 
            category: null,
            concentration: null,
            concentrationUnit: null, 
            doseValue: null,
            doseUnit: null, 
            frequency: null,
        },
        reliever: {
            medicationId: null,
            name: null, 
            category: null,
            concentration: null,
            concentrationUnit: null, 
            doseValue: null,
            doseUnit: null, 
            frequency: null,
        },
        others: []
    });
    const [red, setRed] = useState({
        preventer: {
            medicationId: null,
            name: null, 
            category: null,
            concentration: null,
            concentrationUnit: null, 
            doseValue: null,
            doseUnit: null, 
            frequency: null,
        },
        reliever: {
            medicationId: null,
            name: null, 
            category: null,
            concentration: null,
            concentrationUnit: null, 
            doseValue: null,
            doseUnit: null, 
            frequency: null,
        },
        others: []
    });

    const dropdownGenerator = (med, field) => {
        // console.log(med, field);
        // console.log(medData);
        if (!medData || Object.keys(medData).length === 0) {
            return null;
        } else if (!med || !medData[med]) {
            return null;
        } else if (field=="qty") {
            let options = []
            const unit = medData[med].doseUnit;
            for (let i=1; i<7; i++) {
                options.push({ value: i, label: i+" "+unit })
            }
            return options;
        } else {
            const options = medData[med][field]; 
            const unit = medData[med].concentrationUnit;
            // console.log(options);
            return options.map((entry) => {
                return { value: entry, label: entry+" "+unit }
            }); 
        }
        
    }

    const getDropdownDefault = (medID, type, options) => {
        // console.log("default option",medID,type);
        if (!options || Object.keys(options).length === 0) {
            // console.log("no medOptions");
            return null;
        }
        // const options = medOptions[type];
        for (let entry in options) {
            // console.log(options[entry]);
            if (options[entry].value == medID) {
                // console.log("found default",options[entry]);
                return options[entry];
            }
        }
        console.log("could not find default");
        return null;
    }

    const fetchData = async () => {
        const {preventer, reliever, oral} = await getAllMedicationData();
        let MedDataList = {};
        const preventerOptions = preventer.map((entry) => {
            MedDataList[entry._id.toString()] = entry;
            return { value: entry._id.toString(), label: entry.name }
        })
        const relieverOptions = reliever.map((entry) => {
            MedDataList[entry._id.toString()] = entry;
            return { value: entry._id.toString(), label: entry.name }
        })
        const oralOptions = oral.map((entry) => {
            MedDataList[entry._id.toString()] = entry;
            return { value: entry._id.toString(), label: entry.name }
        })
        setMedOptions({
            preventer: preventerOptions,
            reliever: relieverOptions,
            oral: oralOptions,
        })
        setMedData(MedDataList);
        // console.log(MedDataList);

        const resAAPData = await getPatientAAP(patientId); 
        // console.log(resAAPData);
        if (resAAPData) {
            const newGreen = {...green,...resAAPData.aap.green}
            const newYellow = {...yellow,...resAAPData.aap.yellow}
            const newRed = {...red,...resAAPData.aap.red}
            console.log(newGreen);
            setGreen(newGreen);
            setYellow(newYellow);
            setRed(newRed);
            setMetadata(prevState => {
                return {
                    ...prevState,
                    modifiedByName: resAAPData.modifiedByName,
                    lastModified: resAAPData.aap.modifications[(resAAPData.aap.modifications.length)-1] ? resAAPData.aap.modifications[(resAAPData.aap.modifications.length)-1].date : null,
                };
            });
        };
    }

    useEffect( async () => {
        
        setMedData(null); 

        setIsLoading(true);
        const {valid} = await getAllSessions();
        // let userHasAccess = true;
        let userHasAccess = false;
        for (let i in valid) {
            if (valid[i].patientId && valid[i].patientId.toString() === patientId) {
                console.log("found it");
                userHasAccess = true;
                let patientName = valid[i].patientName;
                setMetadata(prevState => {
                    return {
                        ...prevState,
                        patientName: patientName,
                    };
                });
                break;
            } 
        }
        if (userHasAccess) {
            await fetchData();
        } 
        setIsLoading(false);
    }, []);

    const selectStyle = {
        control: (provided, state) => ({
            ...provided,
            border: "solid 2px #36A0EB",
            marginBottom: "10px",
            marginTop: "10px",
            paddingLeft: "5px",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#36A0EB" : "white",
        }),
    };
    const handleDropdown = (type, name, zoneSetter, selected, otherEntryId=null) => {
        const value = (selected) ? selected.value : null;
        if (name == "medicationId") {
            console.log("clearing all fields in row");
            const medUsed = medData[value].name;
            const medCategory = medData[value].category;
            const medConcUnit = medData[value].concentrationUnit;
            const medDoseUnit = medData[value].doseUnit;
            console.log(medUsed[value]);
            console.log(medUsed,medCategory,medConcUnit,medDoseUnit);
            if (type == "others") {
                zoneSetter((prevState) => {
                    const oldList = prevState.others;
                    let newList = [];
                    for (let i in oldList) {
                        // oldList[i]
                        if (oldList[i].id == otherEntryId) {
                            let newEntry = {
                                id: oldList[i].id,
                                [name]: value,
                                name: medUsed, 
                                category: medCategory,
                                concentration: null,
                                concentrationUnit: medConcUnit, 
                                doseValue: null,
                                doseUnit: medDoseUnit, 
                                frequency: null,
                            };
                            newList = newList.concat(newEntry);
                        } else {
                            newList = newList.concat(oldList[i]);
                        }
                    }
                    console.log(newList);
                    return {
                        ...prevState,
                        others: newList,
                    };
                });
            } else {
                zoneSetter((prevState) => {
                    return {
                        ...prevState,
                        [type]: {
                            ...prevState[type],
                            [name]: value,
                            name: medUsed, 
                            category: medCategory,
                            concentration: null,
                            concentrationUnit: medConcUnit, 
                            doseValue: null,
                            doseUnit: medDoseUnit, 
                            frequency: null,
                        },
                    };
                });
            }
            

            return;
        }
        if (type == "others") {
            zoneSetter((prevState) => {
                const oldList = prevState.others;
                let newList = [];
                for (let i in oldList) {
                    if (oldList[i].id == otherEntryId) {
                        let newEntry = oldList[i];
                        newEntry[name] = value; 
                        newList = newList.concat(newEntry);
                    } else {
                        newList = newList.concat(oldList[i]);
                    }
                }
                return {
                    ...prevState,
                    others: newList,
                };
            });
        } else {
            zoneSetter((prevState) => {
                return {
                    ...prevState,
                    [type]: {
                        ...prevState[type],
                        [name]: value,
                    },
                };
            });
        }
        // console.log(green,yellow,red);
    };

    const handleChange = (event, medType, zoneSetter, otherEntryId=null) => {
        const { name, value } = event.target;
        console.log(name, value);
        if (medType=="others") {
            zoneSetter((prevState) => {
                const oldList = prevState.others;
                let newList = [];
                for (let i in oldList) {
                    if (oldList[i].id == otherEntryId) {
                        let newEntry = oldList[i];
                        newEntry[name] = value; 
                        newList = newList.concat(newEntry);
                    } else {
                        newList = newList.concat(oldList[i]);
                    }
                }
                return {
                    ...prevState,
                    others: newList,
                };
            });
        } else {
            zoneSetter((prevState) => {
                return {
                    ...prevState,
                    [medType] : {
                        ...prevState[medType],
                        [name]: value,
                    }
                };
            });
        }
        
        // console.log(green,yellow,red);
    };

    const isEntryValid = (entry, fieldsToCheck=["concentration","doseValue","frequency"]) => {
        console.log(entry);
        if (entry.medicationId) {
            for (let field in fieldsToCheck) {
                field = fieldsToCheck[field];
                if (!entry[field]) {
                    console.log(field,"not entered");
                    return false;
                }
            }
        } else {
            console.log("empty row");
        }
        console.log("alls good");
        return true;
    }

    const saveAAP = () => {
        console.log("Saving AAP...")
        console.log(green); 
        console.log(yellow); 
        console.log(red);  
        let zones = [green, yellow, red];
        let numErrors = 0;
        for (let zone in zones) {
            zone = zones[zone];
            let zoneName;
            if (zone == green) {
                zoneName = "green";
            } else if (zone == yellow) {
                zoneName = "yellow";
            } else if (zone == red) {
                zoneName = "red";
            } 
            
            
            for (let entry in zone) {
                let rowID = zoneName+"-"+entry;
                console.log(entry);
                if (entry=="others") {
                    for (let otherEntry in zone[entry]) {
                        if (!isEntryValid(zone[entry][otherEntry])) {
                            console.log(rowID+"-"+zone[entry][otherEntry].id);
                            document.getElementById(rowID+"-"+zone[entry][otherEntry].id).style.backgroundColor = "var(--red)";
                            numErrors += 1;
                        } else if (document.getElementById(rowID+"-"+zone[entry][otherEntry].id)) {
                            document.getElementById(rowID+"-"+zone[entry][otherEntry].id).style.backgroundColor = null;
                        };
                    }
                } else if (entry=="beforeExercise") {
                    if (!isEntryValid(zone[entry],["concentration","doseValue"])) {
                        console.log(rowID,"error");
                        document.getElementById(rowID).style.backgroundColor = "var(--red)";
                        numErrors += 1;
                    } else {
                        document.getElementById(rowID).style.backgroundColor = null;
                    };
                } else {
                    if (!isEntryValid(zone[entry])) {
                        console.log(rowID,"error");
                        document.getElementById(rowID).style.backgroundColor = "var(--red)";
                        numErrors += 1;
                    } else {
                        document.getElementById(rowID).style.backgroundColor = null;
                    };
                }
            }
        }
        
        if (numErrors > 0) {
            alert("Please enter all fields in each row highlighted in red.");
        } else {
            setIsSaving(true);
            function filterZone(zone,zoneType){
                if (zoneType === "green") {
                    if (zone.hasOwnProperty("beforeExercise")) {
                        if (zone.beforeExercise.medicationId == null){
                            delete zone.beforeExercise;
                        }
                    }
                } 
                if (zone.hasOwnProperty("preventer")) {
                    if (zone.preventer.medicationId == null){
                        delete zone.preventer;
                    }
                }
                if (zone.hasOwnProperty("reliever")) {
                    if (zone.reliever.medicationId == null){
                        delete zone.reliever;
                    }
                }
                if (zone.hasOwnProperty("others")) {
                    if (zone.others.length == 0){
                        delete zone.others;
                    }
                }
                return zone
            }
            const greenCopy = { ...green };
            const yellowCopy = { ...yellow };
            const redCopy = { ...red };
            let greenZone = filterZone(greenCopy,"green");
            let yellowZone = filterZone(yellowCopy,"yellow");
            let redZone = filterZone(redCopy,"red");

            let newAAP = {
                green: greenZone, 
                yellow: yellowZone,
                red: redZone,
            }
            console.log(newAAP)
            updatePatientAAP(patientId, newAAP);
            setIsSaving(false);
        }
    }

    const renderHeadings = (cols=3) => {
        return (
            <div className='aap-row aap-heading'>
                <h4 className='aap-col' style={{margin:0, marginRight:"10px"}}/>
                <h4 className='aap-col align-center'> Medication </h4> 
                <h4 className='aap-col align-center'> Concentration </h4> 
                <h4 className='aap-col align-center'> Dose </h4> 
                <h4 className='aap-col align-center' style={{width:"22%"}}> When to take it </h4> 
                {/* {cols==4 ? <h4 className='aap-col align-center'> Maximum Dosage </h4> : null}  */}
            </div>
        )
    }
    const renderRow = (zone, zoneSetter, type, otherEntryId=null) => {
        // console.log(zone, type, otherEntryId);
        if (!zone) {
            return;
        }
        // console.log(medID);
        if (!medOptions || Object.keys(medOptions).length === 0) {
            // console.log(medOptions);
            return <p>loading</p>;
        } 
        let medID, otherEntryIndex;
        if (otherEntryId) {
            console.log(zone);
            for (let i in zone.others) {
                if (zone.others[i].id == otherEntryId) {
                    medID = zone.others[i].medicationId;
                    console.log("Other medID",medID);
                    otherEntryIndex = i;
                }
            }
        } else if (type=="prednisolone") {
            medID = metadata.prednisoloneId;
        } else if (!zone[type]) {
            medID = null;
        } else {
            medID = zone[type].medicationId;
        }
        
        let medTypeOptions;
        switch (type) {
            case "preventer":
            case "reliever": 
                medTypeOptions = medOptions[type];
                break; 
            case "beforeExercise": 
                medTypeOptions = medOptions["preventer"];
                break; 
            default: 
                medTypeOptions = medOptions["preventer"].concat(medOptions["reliever"]).concat(medOptions["oral"]);
                break;
        }
        let defaultMed, medConcOptions, defaultConc, qtyOptions, qtyDefault, defaultFreq;
        defaultConc = null;
        qtyDefault = null;
        if (medID) {
            // console.log(medID, medData[medID].name);
            medConcOptions = dropdownGenerator(medID, "concentrations");
            qtyOptions = dropdownGenerator(medID, "qty");
            console.log(medData[medID].name, medConcOptions,qtyOptions);
            defaultMed = getDropdownDefault(medID, type, medTypeOptions);
            let row = (otherEntryId) ? zone[type][otherEntryIndex] : zone[type];
            if ("concentration" in row && medData[medID] && row.concentration) {
                defaultConc = {
                    value: row.concentration, 
                    label: row.concentration + " " + medData[medID].concentrationUnit
                }
            }
            if ("doseValue" in row && medData[medID] && row.doseValue) {
                qtyDefault = {
                    value: row.doseValue, 
                    label: row.doseValue + " " + medData[medID].doseUnit
                }
            }
            defaultFreq = row.frequency;
            // }
        } 
        let zoneName;
        if (zone == green) {
            zoneName = "green";
        } else if (zone == yellow) {
            zoneName = "yellow";
        } else if (zone == red) {
            zoneName = "red";
        } 
        let rowID = zoneName+"-"+type;
        if (otherEntryId) {
            rowID += "-"+otherEntryId;
        }
        
        return (
            <div className='aap-row' id={rowID}>
                <h4 className='aap-col'> 
                    {(type=="beforeExercise") 
                        ? "Before Exercise"
                        : type.charAt(0).toUpperCase()+type.slice(1)
                    } 
                    {(type=="others") 
                        ? <button onClick={() => removeOther(zone,otherEntryId,zoneSetter)} className='Button-round' style={{margin:"0 0 0 10px"}}>-</button>
                        : null
                    }
                </h4> 
                {(type == "prednisolone") 
                    ? <div className='aap-col' style={{height: "24px", width: "20%"}}></div> 
                    : <div className='aap-col'>
                        <Select
                            options={medTypeOptions}
                            // defaultValue={defaultMed}
                            value={defaultMed}
                            name={type}
                            onChange={(selected) =>
                                handleDropdown(type, "medicationId", zoneSetter, selected, otherEntryId)
                            }
                            isClearable={true}
                            styles={selectStyle}
                        />
                    </div>}
                <div className='aap-col'>
                    <Select
                        options={medConcOptions ?? []}
                        // defaultValue={defaultConc}
                        value={defaultConc}
                        name="concentration"
                        onChange={(selected) =>
                            handleDropdown(type, "concentration", zoneSetter, selected, otherEntryId)
                        }
                        styles={selectStyle}
                    />
                </div>
                <div className='aap-col'>
                    <Select
                        options={qtyOptions ?? []}
                        // defaultValue={qtyDefault}
                        value={qtyDefault}
                        name="qty"
                        onChange={(selected) =>
                            handleDropdown(type, "doseValue", zoneSetter, selected, otherEntryId)
                        }
                        styles={selectStyle}
                    />
                </div>
                <input className='aap-col' name="frequency" value={defaultFreq ?? ""} onChange={(e) => handleChange(e,type,zoneSetter,otherEntryId)} />
            </div>
        )
    }

    const renderOther = (zone, zoneSetter) => {
        // console.log(green,yellow,red);
        if (!zone) {
            return;
        }
        let type = "others";
        let entries = zone.others ?? [];        
        return entries.map((entry) => {
            // console.log(entry);
            return (
                renderRow(zone, zoneSetter, type, entry.id)
            );
        });
    }

    const removeOther = (zone, index, zoneSetter) => {
        const oldList = zone.others;
        const newList = oldList.filter((entry) => entry.id !== index);
        zoneSetter((prevState) => {
            return {
                ...prevState,
                others: newList,
            };
        });
    }

    const addOther = (zone, zoneSetter) => {
        console.log("zone.others",zone.others);
        console.log(zone)
        const oldList = zone.others;
        const newEntry = {
            id: uuid(),
            medicationId: null,
            name: null, 
            category: null,
            concentration: null,
            concentrationUnit: null, 
            doseValue: null,
            doseUnit: null, 
            frequency: null, 
        }
        const newList = oldList.concat(newEntry); //oldList.filter((entry) => entry.id !== index);
        
        zoneSetter((prevState) => {
            return {
                ...prevState,
                others: newList,
            };
        });
    }

    if (!medData || Object.keys(medData).length === 0) {
        if (isLoading) {
            return (
                <div className='App-content'>
                    <div className="Landing-section align-center">
                        <img className='loading-icon' src={loading}/>
                    </div>
                </div>
            )
        } else {
            return <NotFoundPage />; 
        }
    }; 

    console.log(metadata);

    return (
        <div className='App-content'>
            <Helmet htmlAttributes>
                <html lang="en" />
                <title>CAMS Web App</title>
                <meta charset="utf-8"/>
            </Helmet>
            <div className='App-content-wrapper add-pad' style={{maxWidth:"1200px"}}>
                <div className='Session-header'> 

                    <div className='Session-header-left'> 
                        <h1> Asthma Action Plan for {metadata.patientName ?? "Unknown User"} </h1>
                        <p> Last updated by {(metadata.modifiedByName) ? "Dr. "+metadata.modifiedByName : "Unknown User"} on {(metadata.lastModified) ? getDateString(metadata.lastModified, true) : "Unknown Date"} </p>
                        <button className='Button-selected' onClick={() => saveAAP()}>Save AAP</button>
                        <img className='loading-icon' src={loading} style={{position:"relative", top:"12px", opacity:(isSaving) ? 1 : 0}}/>
                    </div> 

                    <div id="aap-form">
                        <div className='aap-section'>
                            <h2 style={{color:"var(--green)",marginLeft:"15px"}}> Green Zone </h2>
                            {renderHeadings()}
                            {renderRow(green, setGreen, "preventer")}
                            {renderRow(green, setGreen, "reliever")}
                            {renderRow(green, setGreen, "beforeExercise")}
                            {renderOther(green, setGreen)}
                            <button onClick={() => addOther(green, setGreen)}> + Other Medication</button>
                        </div>

                        <div className='aap-section'>
                            <h2 style={{color:"var(--yellow)",marginLeft:"15px"}}> Yellow Zone </h2>
                            {renderHeadings()}
                            {renderRow(yellow, setYellow, "preventer")}
                            {renderRow(yellow, setYellow, "reliever")}
                            {renderOther(yellow, setYellow)}
                            <button onClick={() => addOther(yellow, setYellow)}> + Other Medication</button>
                        </div>

                        <div className='aap-section'>
                            <h2 style={{color:"var(--red)",marginLeft:"15px"}}> Red Zone </h2>
                            {renderHeadings()}
                            {renderRow(red, setRed, "preventer")}
                            {renderRow(red, setRed, "reliever")}
                            {/* {renderRow(red, setRed, "prednisolone")}s */}
                            {renderOther(red, setRed)}
                            <button onClick={() => addOther(red, setRed)}> + Other Medication</button>                            
                        </div>
                    </div>

                </div> 

                <div className='Session-content'> 

                    
                </div>
            </div>
        </div>
    )

}

export default AAPPage;