import { useContext } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import NotFoundPage from "../pages/NotFoundPage";

const RequireAuth = () => {
  const { login, isValid, isAdmin } = useAuth();
  console.log(login, isValid, isAdmin);
  const location = useLocation();
  // return isValid ? (
  //   <Outlet />
  // ) : login ? (
  //   <Navigate to="/signup/success" state={{ from: location }} replace />
  // ) : (
  //   <Navigate to="/login" state={{ from: location }} replace />
  // );

  // console.log(location.pathname);
  let isAdminPage = location.pathname === "/admin";

  let destination; 
  if (isAdminPage) {
    // console.log("isAdminPage");
    if (isAdmin) {
      destination = <Outlet />;
    } else {
      destination = <NotFoundPage />;
    }
  } else if (isValid) {
    destination = <Outlet />;
  } else {
    if (login) {
      destination = <Navigate to="/signup/success" state={{ from: location }} replace />;
    } else {
      destination = <Navigate to="/login" state={{ from: location }} replace />;
    }
  }
  return destination;
};

export default RequireAuth;
