import React, { Component } from 'react';
import {Helmet} from "react-helmet";

const BlogPage = (props) => {

    React.useEffect(() => {
        props.changeSelectedTab(3);
    }, []);

    return (
        <div className='App-content'>
            <Helmet htmlAttributes>
                <html lang="en" />
                <title>Blog | CAMS</title>
                <meta charset="utf-8"/>
            </Helmet>
            <div className='App-content-wrapper'>
                {/* <h1 className='Landing-heading-large'>Our Blog</h1> */}
                <div className='Maintenance'>             
                    <h1> Coming Soon! </h1>
                    <p> This page is still under development </p>
                </div>
            </div>
        </div>
    )

}

export default BlogPage;