export function getDateString(dateObject, includeTime=false) {
    if (!dateObject) {return ""};
    const currentDate = new Date();
    // console.log(currentDate);
    // console.log(dateObject);
    let year = dateObject.getFullYear();
    let day = dateObject.getDate();
    let month = dateObject.toDateString().slice(4,7);
    // console.log(day, month);
    let dateString = day + " " + month;
    // console.log(dateString);
    if (year !== currentDate.getFullYear()) {
        dateString += " " + year;
    }
    if (includeTime) {
        dateString += " " + dateObject.toLocaleTimeString();
    }
    // let dateString = dateObject.toISOString(); // 2022-06-05T04:13:11.741Z
    // let dateString = dateObject.toGMTString(); // Sun, 05 Jun 2022 10:07:22 GMT
    // let dateString = dateObject.toDateString().slice(3,10); // Jun 05
    return dateString;
}

export function getTimeConcat(dateObject) {
    if (!dateObject) {return ""};
    let time = dateObject.toLocaleTimeString();
    let hour = (time[1]==":") ? time[0] : time.slice(0,2);
    time = hour + " " + time.slice(-2,)
    return time;
}