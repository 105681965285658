import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  registerWithEmailAndPassword,
  sendVerificationEmail,
  logInWithEmailAndPassword,
  createDoctorData,
  alertFirebaseErrors,
} from "../config/FirebaseConfig";
import Select from "react-select";

const SignupPage = (props) => {
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    firstName: null,
    lastName: null,
    mobileNum: null,
    emailAddr: null,
    medRegNum: null,
    password: null,
    doctorType: "gp",
    clinic: null,
    passwordConfirm: null,
  });

  const doctorTypeOptions = [
    { value: "gp", label: "GP" },
    { value: "sp", label: "Specialist" },
  ];
  const selectStyle = {
    control: (provided, state) => ({
      ...provided,
      border: "solid 2px #36A0EB",
      marginBottom: "10px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#36A0EB" : "white",
    }),
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    // console.log(formValue);
  };
  const handleDropdown = (name, selected) => {
    const value = selected.value;
    // console.log(name, value);
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    // console.log(formValue);
  };

  const createUser = async (event) => {
    event.preventDefault();
    console.log(formValue);
    const name = formValue.firstName + " " + formValue.lastName;
    const email = formValue.emailAddr;
    const password = formValue.password;
    const medicalRegistrationNumber = formValue.medRegNum;
    const phoneNumber = parseInt(formValue.mobileNum);
    const clinic = formValue.clinic;
    const doctorType = formValue.doctorType;
    try {
      await registerWithEmailAndPassword(email, password, name);
      await logInWithEmailAndPassword(email, password);
      await sendVerificationEmail(email);
      const doctorData = {
        name: name,
        email: email,
        MRN: medicalRegistrationNumber,
        phoneNumber: phoneNumber,
        clinic: clinic,
        type: doctorType,
        status: "pending",
        approvedBy: "",
        dateApproved: new Date(),
        patients: [],
      };
      createDoctorData(doctorData);
      navigate("/signup/verify");
    } catch (error) {
      const errorMessage = error.message;
      console.log(errorMessage);
      alertFirebaseErrors(errorMessage);
    }
  };

  // const checkAllFieldsFilled = () => {
  //   console.log(formValue);
  //   for (const [key, value] of Object.entries(formValue)) {
  //     // console.log(key, value);
  //     if (value==null) {
  //       alert(key+" empty lol");
  //       return;
  //     }
  //   }
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    // checkAllFieldsFilled();
    if (formValue.password !== formValue.passwordConfirm) {
      alert("Passwords do not match!");
      return;
    }
    createUser(event);
  };

  return (
    <div className="App-content">
      <Helmet htmlAttributes>
        <html lang="en" />
        <title>Sign Up | CAMS</title>
        <meta charset="utf-8" />
      </Helmet>
      <div className="App-content-wrapper">
        <div className="Landing-section">
          <h1 className="Landing-heading-large">Sign Up for Doctors</h1>

          <form
            id="login-form"
            name="signup"
            onSubmit={handleSubmit}
            // method="post"
            //action="/signup/success/"
            // data-netlify-honeypot="bot-field"
            //className="Landing-section"
          >
            <input type="hidden" name="form-name" value="signup" />
            <input name="bot-field" style={{ display: "none" }} />

            <p>
              <label>
                First Name
                <input
                  required
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                />
              </label>
            </p>
            <p>
              <label>
                Last Name
                <input
                  required
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                />
              </label>
            </p>
            <p>
              <label>
                Mobile Number
                <input
                  required
                  type="tel"
                  name="mobileNum"
                  onChange={handleChange}
                  maxLength="8"
                  minLength="8"
                />
              </label>
            </p>
            <p>
              <label>
                Email Address
                <input
                  required
                  type="email"
                  name="emailAddr"
                  onChange={handleChange}
                />
              </label>
            </p>
            <p>
              <label>
                I am a...
                <Select
                  options={doctorTypeOptions}
                  defaultValue={doctorTypeOptions[0]}
                  name="doctorType"
                  onChange={(selected) =>
                    handleDropdown("doctorType", selected)
                  }
                  styles={selectStyle}
                />
              </label>
            </p>
            <p>
              <label>
                Name of Clinic of Practice
                <input
                  required
                  type="text"
                  name="clinic"
                  onChange={handleChange}
                />
              </label>
            </p>
            <p>
              <label>
                Medical Registration Number
                <input
                  required
                  type="text"
                  name="medRegNum"
                  onChange={handleChange}
                />
              </label>
            </p>
            <p>
              <label>
                Password
                <input
                  required
                  type="password"
                  name="password"
                  onChange={handleChange}
                />
              </label>
            </p>
            <p>
              <label>
                Confirm Password
                <input
                  required
                  type="password"
                  name="passwordConfirm"
                  onChange={handleChange}
                />
              </label>
            </p>

            <button
              // onClick={createUser}
              //type="submit"
              className="Button-selected"
            >
              Sign Up
            </button>
            {/* <button className='Button-selected'>Continue with Google</button> */}

            <a href="/login" className="Link-accent align-center">
              Already have an account? <b>Login!</b>
            </a>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
