import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import pfp from '../media/pfp.png'
import logoAZ from '../media/astrazeneca-logo.png'
import pfpKenneth from '../media/team/kenneth.jpg'
import pfpLeong from '../media/team/leongck.jpeg'
import pfpCharis from '../media/team/charis.jpg'
import pfpCK from '../media/team/ck.jpg'
import pfpJustin from '../media/team/justin.jpg'
import pfpKewen from '../media/team/kewen.jpg'
import Footer from './info/Footer';

const AboutPage = (props) => {

    React.useEffect(() => {
        props.changeSelectedTab(1);
    }, []);

    function listTeam() {
        const details = [
            {
                name: "Dr Kenneth Tan",
                role: "Co-Founder",
                pic: pfpKenneth
            },
            {
                name: "Dr Leong Choon Kit",
                role: "Co-Founder",
                pic: pfpLeong
            },
            {
                name: "Charis",
                role: "Product Manager",
                pic: pfpCharis
            },
            {
                name: "Chee Kit",
                role: "Mobile App Developer",
                pic: pfpCK
            },
            {
                name: "Justin",
                role: "Backend Developer",
                pic: pfpJustin
            },
            {
                name: "Kewen",
                role: "Web Developer",
                pic: pfpKewen
            },
        ]
        return details.map( (member) => {
            return (
            <div className='team-profile-card'>
                <img src={member.pic}/>
                <h2>{member.name}</h2>
                <p>{member.role}</p>
            </div>
            )
        })
    }
    // cardify = (entries) => {
    //     return Object.entries(entries).map((entry) => {
    //         console.log(entry);
    //         return <SessionCard sessionUID={entry[0]} details={entry[1]} selectSession={this.setSelectedSession} isSelected={this.state.selectedSession===entry[0]} toggleStar={this.toggleStar}/>;
    //     });
    // };
    
    return (
        <div className='App-content'>
            <Helmet htmlAttributes>
                <html lang="en" />
                <title>About Us | CAMS</title>
                <meta charset="utf-8"/>
            </Helmet>
            <div className='App-content-wrapper no-pad'>

                <div className='Landing-row-wrapper'>
                    <div className='Landing-section info-page'>
                        <h1 className='Landing-heading-large'>About Us</h1>
                        <p>We created mobile and web applications to empower asthma patients to take charge of their condition and receive better preventive care from their doctors.</p>
                    </div>
                </div>

                <div className='Landing-row-wrapper'>
                    <div className='Landing-section'>
                        <h1 className='align-center'>Our Vision</h1>
                        <h2 className='quote1'>"</h2>
                        <h2 className='align-center quote-content'>a world where asthma patients are <b>empowered</b> to <b>take charge of their own health</b></h2>
                        <h2 className='quote2'>"</h2>                    
                    </div>
                </div>

                <div className='Landing-row-wrapper'>
                    <div className='Landing-section info-page'>
                        <h1>Our Mission</h1>
                        <p>At CAMS, we strive to transform the healthcare industry by empowering patients and providing them the means to take charge of their own health.</p>
                    </div>
                </div>

                <div className='Landing-row-wrapper'>
                    <div className='Landing-section'>
                        <h1 className='align-center'>Meet the Team</h1>
                        <div className='Landing-row flex-wrap'>{listTeam()}</div>
                    </div>
                </div>

                {/* <div className='Landing-section'>
                    <h1 className='align-center'>Our Partners</h1>
                    <div className='Landing-row flex-wrap'>
                        <img className='partners-logos' src={logoAZ}/>
                    </div>
                </div> */}

                <Footer/>
                
            </div>
        </div>
    )

}

export default AboutPage;