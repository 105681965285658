import React from 'react';
import '../styles/Navbar.css';
import Logo from '../media/Logo.png'
import pfp from '../media/pfp.png'
import { Link } from "react-router-dom"
import ProfileDropdown from './ProfileDropdown';
import { auth, signInWithGoogle, logout } from "../firebase";
import { getUsername, getDP } from "../functions/LocalStorage";
import Menu from '../media/menu.png';
import Sidebar from './Sidebar';

class Navbar extends React.Component {

  constructor(props) {
    super(props);
    console.log("mobile =",this.props.isMobile);
  }

  state = {
    showProfileDropdown: false,
  }

  toggleProfileDropdown = () => {
    console.log("profile clicked");
    this.setState({
      showProfileDropdown: !this.state.showProfileDropdown
    });
  }

  dp = getDP() ?? pfp;

  render() { 
    return (
      <div className="Navbar">
        <div className="Navbar-wrapper">

          {(this.props.isMobile || this.props.login) 
            ? <span className='float-right'>
                <img src={Menu} className="Navbar-logo" />
                <Sidebar selectedTab={this.props.selectedTab}/> 
              </span>
            : null
          }

          <Link to={(this.props.login) ? "/dashboard" : "/"}>
            <img src={Logo} className="Navbar-logo" onClick={() => this.props.changeSelectedTab(0)} alt="logo" />
          </Link>

          <div className="Dropdown-wrapper"> {this.state.showProfileDropdown 
            ? <ProfileDropdown toggle={this.toggleProfileDropdown} signout={this.props.toggleLogout} />  
            : null
          } 
          </div>
          {(this.props.login) 
            ? <img src={this.dp} onClick={this.toggleProfileDropdown} className="Navbar-pfp" alt="profile" />
            : (this.props.isMobile) 
              ? null
              : <div> 
                  <Link to="/about"><h3 className={(this.props.selectedTab==1)?"Navbar-items Navbar-items-selected":"Navbar-items"} onClick={() => this.props.changeSelectedTab(1)}>About Us</h3></Link>
                  <Link to="/solution"><h3 className={(this.props.selectedTab==2)?"Navbar-items Navbar-items-selected":"Navbar-items"} onClick={() => this.props.changeSelectedTab(2)}>Our Solution</h3></Link>
                  <Link to="/blog"><h3 className={(this.props.selectedTab==3)?"Navbar-items Navbar-items-selected":"Navbar-items"} onClick={() => this.props.changeSelectedTab(3)}>Blog</h3></Link>
                  <Link to="/contact"><h3 className={(this.props.selectedTab==4)?"Navbar-items Navbar-items-selected":"Navbar-items"} onClick={() => this.props.changeSelectedTab(4)}>Contact Us</h3></Link>
                </div>
              
          }
          
          
          {(this.props.login) 
            ? null /*<div className="Navbar-searchbar">
                Search
              </div>*/
            : (this.props.isMobile) 
              ? null
              : <Link to="/login" onClick={() => this.props.changeSelectedTab(0)}><button id='Navbar-login-button' className='Button-selected'>Login for Doctors</button></Link>
          }
          

        </div>
      </div>
    )
  }
}
export default Navbar;