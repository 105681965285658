import React, { Component } from "react";

class NotFoundPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App-content">
        <div className="Landing-section align-center">
          <h1>{"Page Not Found :("}</h1>
          <p>Sorry, we couldn't find the page you were looking for.</p>
          <p>
            Click{" "}
            <a href="/" className="Link-accent">
              here
            </a>{" "}
            to go back to our homepage!
          </p>
        </div>  
      </div>
    );
  }
}
export default NotFoundPage;
