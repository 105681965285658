import React, { Component } from "react";

class VerifyPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App-content">
        <div className="Landing-section align-center">
          <h1>Please check your email to verify!</h1>
          <p>We require you to verify that the email belongs to you.</p>
          <p>Your registration process will start after email verification.</p>
          <p>
            Click{" "}
            <a href="/" className="Link-accent">
              here
            </a>{" "}
            to go back to our homepage!
          </p>
        </div>  
      </div>
    );
  }
}
export default VerifyPage;
