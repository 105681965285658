import React, { Component } from "react";

const FeedbackSuccessPage = (props) => {
  return (
    <div className="App-content">
      <div className="Landing-section align-center">
        <h1>Your feedback has been submitted!</h1>
        <p>Thank you for helping us provide you with a better asthma management experience!</p>
        <p>
          Click{" "}
          <a href="/" className="Link-accent">
            here
          </a>{" "}
          to go back to our homepage!
        </p>
      </div>
    </div>
  );
};

export default FeedbackSuccessPage;
