import React, { Component } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import '../styles/ContactPage.css';
import {Helmet} from "react-helmet";
import Footer from './info/Footer';

const FeedbackPage = (props) => {

    React.useEffect(() => {
        props.changeSelectedTab(0);
    }, []);

    const queryParams = new URLSearchParams(window.location.search);
    const platform = queryParams.get("platform");
    const version = queryParams.get("version");
    // let { platform }  = useParams();
    // platform = platform.toString();
    // console.log(platform);
    
    return (
        <div className='App-content'>
            <Helmet htmlAttributes>
                <html lang="en" />
                <title>Feedback | CAMS</title>
                <meta charset="utf-8"/>
            </Helmet>
            <div className='App-content-wrapper no-pad'>

                <div className='Landing-row-wrapper'>
                    <div className='Landing-section info-page'>
                        <h1 className='Landing-heading-large'>App Feedback</h1>
                        <p>Got bugs to report? Features to suggest? Help us in our journey to provide the best asthma support!</p>
                    </div>
                </div>

                <div className='Landing-row-wrapper'>
                <form 
                    id="contact-form"
                    name="feedback" 
                    method='post' 
                    data-netlify-honeypot='bot-field'
                    className='Landing-section' 
                    action='/feedback/success/'
                >
                    <input type="hidden" name="form-name" value="feedback" />
                    <input name='bot-field' style={{display:"none"}}/>
                    <div className='Contact-left align-left'>
                    <p>
                        <label>Name<input required type="text" name="name" /></label>
                    </p>
                    <p>
                        <label>Email Address<input required type="email" name="email" /></label>
                    </p>
                    </div>
                    <div className='Contact-right align-left'>
                    <p>
                        <label>Message<textarea required name="message"></textarea></label>
                    </p>                    
                    <p>Platform: {platform ?? "Unknown"}</p>
                    <p>Version: {version ?? "Unknown"}</p>
                    <input name="platform" value={platform} style={{display:"none"}}/>
                    <input name="version" value={version} style={{display:"none"}}/>
                    <p>
                        <button id="Contact-submit" type="submit" className='Button-selected'>Send</button>
                    </p>
                    </div>
                    
                </form>
                </div>

                <Footer/>

            </div>
        </div>
    )

}

export default FeedbackPage;