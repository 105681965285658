import React from 'react';
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'react-router-dom';
import MenuIcon from '../media/menu.png';

class Sidebar extends React.Component {

  constructor(props) {
    super(props);
  }
//   showSettings (event) {
//     event.preventDefault();
//   }

  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Menu 
        right
        id="sidebar"
        width="200px"
        // pageWrapId={ "page-wrap" } 
        // outerContainerId={ "outer-container" }
        // customBurgerIcon={ <img src={MenuIcon} className="Navbar-logo"/> }
      >
        <a className={(this.props.selectedTab==0) ? "menu-item menu-item-selected" : "menu-item"} href="/"><h3>Home</h3></a>
        <a className={(this.props.selectedTab==1) ? "menu-item menu-item-selected" : "menu-item"} href="/about"><h3>About Us</h3></a>
        <a className={(this.props.selectedTab==2) ? "menu-item menu-item-selected" : "menu-item"} href="/solution"><h3>Our Solution</h3></a>
        <a className={(this.props.selectedTab==3) ? "menu-item menu-item-selected" : "menu-item"} href="/blog"><h3>Blog</h3></a>
        <a className={(this.props.selectedTab==4) ? "menu-item menu-item-selected" : "menu-item"} href="/contact"><h3>Contact Us</h3></a>
        {(this.props.login) 
          ? null /*<div className="Navbar-searchbar">
              Search
            </div>*/
          : (this.props.isMobile) 
            ? null
            : <Link to="/login" onClick={() => this.props.changeSelectedTab(0)}><button id='Navbar-login-button' className='Button-selected menu-item'>Login for Doctors</button></Link>
        }
        {/* <a onClick={ this.showSettings } className="menu-item--small" href="">Settings</a> */}
      </Menu>
    );
  }
}
export default Sidebar;