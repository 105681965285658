import React from 'react';

const Footer = (props) => {

    return (
        <div className='Landing-row-wrapper grey-row'>
            <div className='Landing-section'>
                <hr/>
                <a href="/terms-conditions" className='Link-accent display-block'>Terms and Conditions</a>
                <a href="/privacy-policy" className='Link-accent display-block'>Privacy Policy</a>
                <a href="/contact" className='Link-accent display-block'>Contact Us</a>
                <a href="/about" className='Link-accent display-block'>About Us</a>
                <hr/>
                <p>Copyright 2022 CAMS, All rights reserved.</p>
                <p>Last Updated August 2022</p>
            </div>
        </div>
    )

}

export default Footer;