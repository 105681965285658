let storage = window.localStorage;

export function removeAll() {
  removeToken();
  removeRealmUser();
  removeUsername();
  removeDP();
  removeValidUser();
  removeIsAdmin();

  console.log("removeAll");
}

// Token
export function getToken() {
  return JSON.parse(storage["token"]);
}

export function setToken(newToken) {
  storage.setItem("token", JSON.stringify(newToken));
}

export function removeToken() {
  storage.removeItem("token");
}

// Realm User
export function getRealmUser() {
  return JSON.parse(storage["realmUser"]);
}

export function setRealmUser(realmUser) {
  storage.setItem("realmUser", JSON.stringify(realmUser));
}

export function removeRealmUser() {
  storage.removeItem("realmUser");
}

// Username
export function getUsername() {
  return storage["username"];
}

export function setUsername(username) {
  storage.setItem("username", username);
}

export function removeUsername() {
  storage.removeItem("username");
}

// Display Picture
export function getDP() {
  return storage["dp"];
}

export function setDP(dpURL) {
  storage.setItem("dp", dpURL);
}

export function removeDP() {
  storage.removeItem("dp");
}

// Valid User 
export function getValidUser() {
  try {
    const value = storage["valid"];
    return (JSON.parse(value) === true);
  } catch (error) {
    console.log(error);
  }
  return false;
}

export function setValidUser(valid) {
  storage.setItem("valid", valid);
}

export function removeValidUser() {
  storage.removeItem("valid");
}

// Is admin
export function getIsAdmin() {
  try {
    const value = storage["admin"];
    return (JSON.parse(value) === true);
  } catch (error) {
    console.log(error);
  }
  return false;
}

export function setIsAdmin(isAdmin) {
  storage.setItem("admin", isAdmin);
}

export function removeIsAdmin() {
  storage.removeItem("admin");
}
