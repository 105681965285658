import React, { Component } from 'react';

const ProfilePage = () => {

    return (
        <div className='App-content'>
            <h1>My Profile</h1>
        </div>
    );

}
export default ProfilePage;
