import React, { useState } from "react";
import { alertFirebaseErrors, sendPasswordReset } from "../config/FirebaseConfig";

const ForgetPasswordPage = (props) =>  {
  const [email, setEmail] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEmail(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("email", email);
    try {
      const res = await sendPasswordReset(email);
      console.log(res);

    } catch (error) {
      const errorMessage = error.message;
      console.log(errorMessage);
      alertFirebaseErrors(errorMessage);
    }
  };

  return (
    <div className="App-content">
      <div className="Landing-section align-center">
        
        <form onSubmit={handleSubmit} id="login-form">
          <h1>Forgot your password?</h1>
          <p>
            <label>
              Please enter your email below and we'll send you a message to help you reset your password.
              <input
                required
                type="email"
                name="email"
                value={email}
                onChange={handleInputChange}
              />
            </label>
          </p>
          <button type="submit" className="Button-selected">
            Reset Password
          </button>

        </form>
        
      </div>  
    </div>
  );
  
};

export default ForgetPasswordPage;