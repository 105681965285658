import React from "react";
import { useSearchParams } from "react-router-dom";

const SuccessPage = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const emailAddr = searchParams.get("emailAddr");

  return (
    <div className="App-content">
      <div className="Landing-section align-center">
        <h1>Your email {emailAddr} has been verified!</h1>
        <p>We will contact you as soon as possible to verify your credentials.</p>
        <p>
          Click{" "}
          <a href="/" className="Link-accent">
            here
          </a>{" "}
          to go back to our homepage!
        </p>
        {/* <o>{emailAddr}</o> */}
      </div>
    </div>
  );
};

export default SuccessPage;
