import React from 'react';
import star from "../media/iconStarSolid.png"
import starHollow from "../media/iconStarHollow.png"

class SessionCard extends React.Component {

    constructor(props) {
        super(props);
    }

    // toggleStar = (e) => {
    //     this.props.details.starred = !this.props.details.starred;
    //     e.stopPropagation();
    //     // TODO: update API here?
    // }

    subtitle = (this.props.expired) 
        ? <p>Shared on {this.props.details.dateCreated.toDateString()}</p>
        : <p>Expires on {this.props.details.endDate.toDateString()}</p>

    render() {
        return (
            <div className={this.props.isSelected ? 'Session-entry Session-entry-selected':'Session-entry'} onClick={() => this.props.selectSession(this.props.sessionUID)}>
                <div className='Session-details'> 
                    <h3>{this.props.details.patientName}</h3>
                    {this.subtitle}
                </div>
                <div className='Session-star-wrapper'>
                    {this.props.details.starred 
                        ? <img src={star} className="Star-icon Session-star" alt="star" onClick={() => this.props.toggleStar(this.props.sessionUID, false, this.props.expired)}/>
                        : <img src={starHollow} className="Star-icon Session-star Session-star-hollow" alt="star" onClick={() => this.props.toggleStar(this.props.sessionUID, true, this.props.expired)} />
                    }  
                    {this.props.details.starred 
                        ? <span class="tooltiptext">Remove flag</span>
                        : <span class="tooltiptext">Flag session</span>
                    }
                </div>
                
            </div>
        )
    }

}
export default SessionCard