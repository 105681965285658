import React, { Component, useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import "../styles/QuizPage.css";
import { ageOptions, symptomsOptions, awakenOptions, sabaOptions, interfereOptions, lungOptions, exaOptions } from "../components/Quiz.js";
import { calcStep, symptomsSeverity, awakenSeverity, sabaSeverity, interfereSeverity, lungSeverity, exaSeverity } from "../components/Quiz.js";


const QuizInitiatePage = (props) => {

    React.useEffect(() => {
        props.changeSelectedTab(0);
    }, []);

    const [age, setAge] = useState(null);
    const [symptoms, setSymptoms] = useState(null);
    const [awaken, setAwaken] = useState(null);
    const [saba, setSaba] = useState(null);
    const [interfere, setInterfere] = useState(null);
    const [lung, setLung] = useState(null);
    const [exa, setExa] = useState(null);

    function renderQuestion(options, variable, name, setter) {
        return options.map((option) => {
            return (<label class="form-radio-label"> 
                        <input class="form-radio" name={name} type="radio" value={option} checked={variable === option} onChange={(e) => setter(e.target.value)} />
                        {option}
                    </label>)
        });
    }

    function getStep() { 
        let severities = {};
        severities["symptoms"] = symptomsSeverity(symptoms);
        severities["awaken"] = awakenSeverity(awaken, age);
        severities["saba"] = sabaSeverity(saba, age);
        severities["interfere"] = interfereSeverity(interfere);
        severities["lung"] = lungSeverity(lung, age); 
        severities["exa"] = exaSeverity(exa);
        return calcStep(severities, age);
    }

    function renderStep() {
        getStep();
        if (age && symptoms && awaken && saba && interfere && lung && exa) { // all questions answered
            return (
                <div className='align-center'>
                    <h1>Your Recommended Step is...</h1>
                    <h2>{getStep()}</h2>
                </div>
            ) 
        } else { // some questions not answered yet
            return (
                <h1 className='align-center'>Answer all the above questions to determine the step!</h1>
            )
        }
    }

    return (
        <div className='App-content'>
            <Helmet htmlAttributes>
                <html lang="en" />
                <title>Initiating Therapy | CAMS</title>
                <meta charset="utf-8"/>
            </Helmet>
            <div className='App-content-wrapper'>
                <div className='Landing-section'>
                <h1>Which Step for Initiating Therapy</h1>
                <form>
                    <div className='Section no-side-margin'>
                        <h2> Impairment (Over the past 4 weeks) </h2>
                        
                        <h4> Patient's Age </h4>
                        {renderQuestion(ageOptions, age, "age", setAge)}

                        <h4> Symptoms </h4>
                        {renderQuestion(symptomsOptions, symptoms, "symptoms", setSymptoms)}

                        <h4> Nighttime Awakenings </h4>
                        {renderQuestion(awakenOptions, awaken, "awaken", setAwaken)}

                        <h4> SABA use for symptom control (not to prevent EIB) </h4>
                        {renderQuestion(sabaOptions, saba, "saba", setSaba)}

                        <h4> Interference with normal activity </h4>
                        {renderQuestion(interfereOptions, interfere, "interfere", setInterfere)}

                        <h4> Lung Function - FEV₁ (% predicted) </h4>
                        {renderQuestion(lungOptions, lung, "lung", setLung)} {/* TODO: grey out this question if age 0-4*/}
                    </div>
                    <div className='Section no-side-margin'>
                        <h2> Risk </h2>

                        <h4> Asthma exacerbations requiring oral systemic corticosteroids </h4>
                        {renderQuestion(exaOptions, exa, "exa", setExa)}
                    </div>
                </form>

                {renderStep()}

                </div>
            </div>
        </div>
    )

}

export default QuizInitiatePage;