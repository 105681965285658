// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  signOut,
  updateProfile,
} from "firebase/auth";
import { RealmApp } from "./realm";
import * as Realm from "realm-web";
import {
  setToken,
  setRealmUser,
  setUsername,
  setDP,
  removeAll,
} from "../functions/LocalStorage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCk6_u9Ii0Xs9D_40Jbu2J8M43T9psKDGk",
  authDomain: "cams-ed43f.firebaseapp.com",
  projectId: "cams-ed43f",
  storageBucket: "cams-ed43f.appspot.com",
  messagingSenderId: "142220352368",
  appId: "1:142220352368:web:b8b9bc156410baf6dd0db5",
  measurementId: "G-X623HK4XXY",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const auth = getAuth(firebaseApp);
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  const firebaseRes = await signInWithPopup(auth, googleProvider);
  console.log(firebaseRes);
  const firebaseToken = await auth.currentUser.getIdToken();
  const credentials = Realm.Credentials.jwt(firebaseToken);
  const realmRes = await RealmApp.logIn(credentials);
  setRealmUser(realmRes);
  const dp = auth.currentUser.photoURL;
  const username = auth.currentUser.displayName;

  // await signInWithPopup(auth, googleProvider)
  //   .then(async (res) => {
  //     console.log("signInWithPopup");
  //     console.log(res);
  //     const firebaseToken = await auth.currentUser.getIdToken();
  //     const credentials = Realm.Credentials.jwt(firebaseToken);
  //     RealmApp.logIn(credentials)
  //       .then((realmUser) => {
  //         console.log(realmUser);
  //         setRealmUser(realmUser);
  //         const dp = auth.currentUser.photoURL;
  //         const username = auth.currentUser.displayName;
  //         console.log(dp, username);
  //         setUsername(username);
  //         setDP(dp);
  //         callback();
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     alert(error.message);
  //   });
};

const logInWithEmailAndPassword = async (email, password) => {
  const firebaseRes = await signInWithEmailAndPassword(auth, email, password);
  console.log(firebaseRes);
  const firebaseToken = await auth.currentUser.getIdToken();
  const credentials = Realm.Credentials.jwt(firebaseToken);
  const realmRes = await RealmApp.logIn(credentials);
  setRealmUser(realmRes);
  // const dp = auth.currentUser.photoURL;
  const username = auth.currentUser.displayName;
  console.log(username);
};

const createDoctorData = async (doctorData) => {
  console.log(RealmApp.currentUser.id);
  const res = await RealmApp.currentUser.functions.updateDoctorCustomData(
    doctorData
  );
  console.log(res);
  return res;
};

const checkValidUser = async () => {
  console.log("checking if valid user");
  const res = await RealmApp.currentUser.functions.callFunction(
    "checkValidDoctor"
  );
  console.log(res);
  return res;
};
const checkValidAdmin = async () => {
  console.log("checking if valid admin");
  const res = await RealmApp.currentUser.functions.callFunction(
    "checkAdmin"
  );
  console.log(res);
  return res;
};

const registerWithEmailAndPassword = async (email, password, displayName) => {
  const res = await createUserWithEmailAndPassword(auth, email, password);
  console.log(res.user);
  const updatedRes = await updateProfile(auth.currentUser, {
    displayName: displayName,
  });
  console.log(auth.currentUser);
  console.log(updatedRes);
  return updatedRes;
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent! Please check your email.");
  } catch (err) {
    console.error(err);
    alertFirebaseErrors(err.message);
  }
};

const sendVerificationEmail = async (emailAddr) => {
  const actionCodeSettings = {
    canHandleCodeInApp: false,
    url: "https://cams.sg/signup/success/?emailAddr=" + emailAddr,
  };
  sendEmailVerification(auth.currentUser, actionCodeSettings).then(() => {
    console.log("email verification sent");
  });
};

const logout = () => {
  console.log("logout");
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      RealmApp.currentUser.logOut();
      removeAll();
      console.log("Successfully logged out");
    })
    .catch((error) => {
      // An error happened.
      console.log("Could not log out...", error);
    });
};

const isLoggedIn = () => {
  // console.log(RealmApp.currentUser);
  if (
    RealmApp.currentUser == null ||
    RealmApp.currentUser.isLoggedIn == false
  ) {
    // not sure if this is the best way to check
    console.log("not logged in");
    return false;
  }
  console.log("yas logged in");
  const username = RealmApp.currentUser.profile.name;
  // console.log(username);
  setUsername(username);
  console.log(RealmApp.currentUser.id);
  return true;
};

const alertFirebaseErrors = (errorMessage) => {
  switch (errorMessage) {
    case "Firebase: Error (auth/wrong-password).":
      alert("Wrong password!");
      break;
    case "Firebase: Error (auth/user-not-found).":
      alert("User not found! Try signing up instead.");
      break;
    case "Firebase: Error (auth/invalid-email).":
      alert("Invalid email!");
      break;
    case "Firebase: Password should be at least 6 characters (auth/weak-password).":
      alert("Password should be at least 6 characters.");
      break;
    case "Firebase: Error (auth/email-already-in-use).":
      alert("This email address has already been registered. Please sign in or verify your credentials.");
      break;
    default:
      alert("An error occurred, " + errorMessage);
      break;
  }
};

const getAllDoctors = async () => {
  console.log("retrieving verified & unverified doctors");
  const res = await RealmApp.currentUser.functions.callFunction(
    "getAllDoctors"
  );
  console.log(res);
  return res;
};

const getQRCode = async () => { 
  console.log("getting doctor's QR code");
  const res = await RealmApp.currentUser.functions.callFunction(
    "getQRCode"
  );
  console.log(res);
  return res;
};

const updateDoctorStatus = async (doctorId, status) => {
  console.log(doctorId, status);
  const res = await RealmApp.currentUser.functions.updateDoctorStatus(
    doctorId, status
  );
  console.log(res);
  return res;
};

const getAllSessions = async () => { 
  console.log("getting all sessions");
  const res = await RealmApp.currentUser.functions.callFunction(
    "getAllSessions"
  );
  console.log(res);
  return res;
};

const getSummaryData = async (sessionID) => { 
  console.log("getting summary data for session id = "+sessionID);
  let res = null;
  res = await RealmApp.currentUser.functions.getSummaryData(
    sessionID.toString()
  ).catch((error) => {
    console.log(error);
  });
  console.log(res);
  return res;
};

const getSessionData = async (sessionID) => { 
  console.log("getting session data for session id = "+sessionID);
  const res = await RealmApp.currentUser.functions.getSessionData(
    sessionID.toString()
  );
  console.log(res);
  return res;
};

const toggleSessionStar = async (sessionID, isStarred) => { 
  console.log("star = "+isStarred+" for session id = "+sessionID);
  const res = await RealmApp.currentUser.functions.updateSession(
    sessionID, isStarred
  );
  console.log(res);
  return res;
};

const getMedicationData = async (medID) => { 
  console.log("getting medication data for med id = "+medID);
  const res = await RealmApp.currentUser.functions.getMedicationData(
    medID.toString()
  );
  console.log(res);
  return res;
};

const getAllMedicationData = async () => { 
  console.log("getting all medication data");
  const res = await RealmApp.currentUser.functions.getAllMedicationData();
  console.log(res);
  return res;
};

const getPatientAAP = async (patientId) => { 
  console.log("getting aap data for patientId = "+patientId);
  const res = await RealmApp.currentUser.functions.getPatientAAP(
    patientId.toString()
  ).catch((error) => {
    console.log(error);
  });
  console.log(res);
  return res;
};

const updatePatientAAP = async (patientId, newAAP) => { 
  console.log("updating aap data for patientId = "+patientId);
  const res = await RealmApp.currentUser.functions.upsertPatientAAP(
    patientId.toString(),
    newAAP
  ).catch((error) => {
    console.log(error);
    alert("Error:",error);
    return false;
  });
  console.log(res);
  alert("AAP updated successfully!");
  return true;
  // return res;
};

export {
  auth,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  sendVerificationEmail,
  logout,
  isLoggedIn,
  checkValidUser,
  checkValidAdmin,
  createDoctorData,
  alertFirebaseErrors,
  getAllDoctors,
  getQRCode,
  updateDoctorStatus,
  getAllSessions,
  getSummaryData,
  getSessionData,
  toggleSessionStar,
  getMedicationData,
  getAllMedicationData,
  getPatientAAP,
  updatePatientAAP,
};
