import React, { Component } from "react";
import { useSearchParams } from "react-router-dom";

const ContactSuccessPage = (props) => {
  return (
    <div className="App-content">
      <div className="Landing-section align-center">
        <h1>Your query has been submitted!</h1>
        <p>We'll get back to you as soon as possible.</p>
        <p>
          Click{" "}
          <a href="/" className="Link-accent">
            here
          </a>{" "}
          to go back to our homepage!
        </p>
      </div>
    </div>
  );
};

export default ContactSuccessPage;
