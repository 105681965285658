import React from 'react';
import { getDateString, getTimeConcat } from '../functions/DateString';

class CalendarPopup extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props.content);
        switch (this.props.category) {
            case "reliever": 
                this.color = "var(--reliever)"; // blue
                this.category = "Reliever"; 
                this.title = this.props.content.name;
                this.subtitle = this.props.content.doseValue + " " + (this.props.content.doseUnit ?? "units");
                break;
            case "preventer": 
                this.color = "var(--preventer)"; // yellow
                this.category = "Preventer"; 
                this.title = this.props.content.name;
                this.subtitle = this.props.content.doseValue + " " + (this.props.content.doseUnit ?? "units");
                break;
            case "oral": 
                this.color = "var(--oral)"; // green
                this.category = "Oral"; 
                this.title = this.props.content.name;
                this.subtitle = this.props.content.doseValue + " " + (this.props.content.doseUnit ?? "units");
                break;
            case "peak": 
                this.color = "var(--peak)"; // grey 
                this.title = "Peak Flow"; 
                this.subtitle = this.props.content.score; 
                break;
            case "attack": 
                this.color = "var(--attack)"; // red 
                this.title = "Attack"; 
                this.subtitle = this.props.content.triggers ? this.props.content.triggers.join(", ") : null; 
                break;
            case "acq": 
                this.color = "var(--acq)"; // purple 
                this.title = "ACQ"; 
                this.subtitle = this.props.content.score; 
                break;
            default: 
                this.color = "grey";
                this.title = "Unsupported Content"; 
                this.subtitle = "";
                break; 
        }
    }

    render() {
        return (
            <div>
                <div className='tooltiptext-positioner'>
                    <div class="tooltiptext">
                        <div>
                            <h3>{this.title}</h3>
                            {(this.category) ? <p>({this.category})</p> : null}
                        </div>
                        <div>
                            <p>{(this.props.content.date) ? getDateString(this.props.content.date, true) : getDateString(this.props.content.dateCreated, true)}</p>
                        </div>
                        {(this.props.content.concentration) 
                            ? <div>
                                <p>Concentration: {this.props.content.concentration} {this.props.content.concentrationUnit ?? "units"}</p>
                            </div> 
                            : null
                        }
                        {(this.props.content.doseValue) 
                            ? <div> 
                                <p>Dosage: {this.props.content.doseValue} {this.props.content.doseUnit ?? "units"}</p>
                            </div>
                            : null
                        }
                        {(this.props.content.score) 
                            ? <div> 
                                <p>Score: {this.props.content.score}</p>
                            </div>
                            : null
                        }
                        {(this.props.content.highestScore) 
                            ? <div> 
                                <p>Highest Score: {this.props.content.highestScore}</p>
                            </div>
                            : null
                        }
                        {(this.props.content.isEmergencyVisit != null) 
                            ? <div> 
                                <p>{this.props.content.isEmergencyVisit ? "Required emergency visit" : "No emergency visit"}</p>
                            </div>
                            : null
                        }
                        {(this.props.content.triggers) 
                            ? <div> 
                                <p>Triggers: {this.props.content.triggers.join(", ")}</p>
                            </div>
                            : null
                        }
                        {(this.props.content.notes) 
                            ? <div> 
                                <p>Notes: {this.props.content.notes}</p>
                            </div>
                            : null
                        }
                    </div>
                </div>
                <span class="event-time">{(this.props.content.date) ? getTimeConcat(this.props.content.date) : getTimeConcat(this.props.content.dateCreated)}</span>
                <div className='event-content' style={{backgroundColor: this.color}}>
                    <h3>{this.title}</h3>
                    <p>{this.subtitle}</p>
                </div>
            </div>
        )
    }

}
export default CalendarPopup