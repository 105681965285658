import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "../styles/LoginPage.css";
import { useNavigate } from "react-router-dom";
import {
  checkValidUser,
  logInWithEmailAndPassword,
  signInWithGoogle,
  alertFirebaseErrors,
  checkValidAdmin,
} from "../config/FirebaseConfig";
import { useAuth } from "../context/AuthProvider";
import { setValidUser, setIsAdmin } from "../functions/LocalStorage";

const LoginPage = (props) => {
  const { setLogin, setValid, setAdmin } = useAuth();
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    email: null,
    password: null,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const email = formValue.email;
    const password = formValue.password;
    console.log("email", email);
    console.log("password", password);
    try {
      const res = await logInWithEmailAndPassword(email, password);
      console.log(res);
      // check if doctor is approved
      const validUser = await checkValidUser();
      console.log(validUser);
      setValidUser(validUser);
      setValid(validUser);
      
      // check if user is admin 
      const isAdmin = await checkValidAdmin();
      console.log(isAdmin);
      setIsAdmin(isAdmin);
      setAdmin(isAdmin);
      // set login status and navigate accordingly
      setLogin(res);
      if (validUser) {
        props.toggleLogin();
        navigate( isAdmin ? "/admin" : "/dashboard");
      } else {
        alert("Your account has not been verified yet.");
        event.preventDefault();
      }
    } catch (error) {
      const errorMessage = error.message;
      console.log(errorMessage);
      alertFirebaseErrors(errorMessage);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const responseGoogle = async () => {
    try {
      const res = await signInWithGoogle();
      console.log(res);
      const validUser = await checkValidUser();
      console.log(validUser);
      setValidUser(validUser);
      setValid(validUser);
      setLogin(res);
      if (validUser) {
        props.toggleLogin();
        navigate("/dashboard");
      } else {
        alert("Your account has not been verified yet.");
      }
    } catch (error) {
      const errorMessage = error.message;
      console.log(errorMessage);
    }
  };

  return (
    <div className="App-content">
      <Helmet htmlAttributes>
        <html lang="en" />
        <title>Login | CAMS</title>
        <meta charset="utf-8" />
      </Helmet>
      <div className="App-content-wrapper">
        <div className="Landing-section">
          <h1 className="Landing-heading-large">Web App Login for Doctors</h1>
          <form
            id="login-form"
            name="login"
            onSubmit={handleSubmit}
            // method='post'
            // action='/success/'
          >
            {/* <input type="hidden" name="form-name" value="contact" /> */}
            {/* <input name='bot-field' style={{display:"none"}}/> */}

            <p>
              <label>
                Email Address
                <input
                  required
                  type="email"
                  name="email"
                  value={formValue.email}
                  onChange={handleInputChange}
                />
              </label>
            </p>
            <p>
              <label>
                Password
                <input
                  required
                  type="password"
                  name="password"
                  value={formValue.password}
                  onChange={handleInputChange}
                />
              </label>
            </p>

            <a href="/forgetpassword" className="Link-accent align-right">
              Forgot Password?
            </a>

            <button type="submit" className="Button-selected">
              Login
            </button>

            {/* <button className="Button-selected" onClick={responseGoogle}>
              Continue with Google
            </button> */}

            <a href="/signup" className="Link-accent align-center">
              Don't have an account? <b>Sign up!</b>
            </a>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
