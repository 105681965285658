import React, { Component } from 'react';
import '../App.css';
import '../styles/LandingPage.css';
// import { Redirect } from 'react-router-dom';
// import GoogleLogin from 'react-google-login';
import { Link } from 'react-router-dom';
// import { getAuth, signInWithRedirect } from "firebase/auth";

import { auth, signInWithGoogle, logout } from "../firebase";
import Logo from '../media/Logo.png'
import LogoLarge from '../media/LogoLarge.jpeg'
import appStoreLogo from '../media/appStore.png'
import googlePlayLogo from '../media/playStore.png'
import mobileApp1 from "../media/demo/Mobile hand.png"
import mobileApp2 from "../media/demo/Android Progress Summary.png"
import mobileApp3 from "../media/demo/Iphone Medication Reminders.png"
import webApp1 from "../media/demo/Web calendar.jpg"
import Footer from './info/Footer';

class LandingPage extends Component {
    constructor(props) {
        super(props);
        props.changeSelectedTab(0);
    }

    render() {
        return (
            <div className='App-content'>
                <div className='App-content-wrapper no-pad'>
                
                    <div className='Landing-row-wrapper'>
                        <div className='Landing-row Landing-first-row flex-wrap'>
                            <div className='Landing-col'>
                                <h1 id='Landing-catchphrase1' className='Landing-heading-large'>Asthma tools</h1>
                                <h1 id='Landing-catchphrase2'>in your pocket</h1>
                                <h2>Improve your <b>asthma control</b> and <b>achieve your goals</b> today!</h2>
                            </div>
                            <div className='Landing-col'>
                                
                                <img style={{position:'relative',left:-350,width:'250%',height:'100%'}} src={mobileApp1} id="Landing-logo"/>
                                
                                {/* <button onClick={this.responseGoogle}>Login with Google</button> */}
                            </div>
                        </div>
                    </div>

                    {/* <div className='Landing-mid'>
                        <p>Scroll to find out more!</p>
                    </div> */}
                    <div className='Landing-row-wrapper accent-row'>
                        <h1 className='Landing-heading-large align-center'>Our Solutions</h1>
                        <div className='Landing-row flex-wrap'>
                            <div className='Landing-col'>
                                <h2>{"Android & iOS App"}</h2>
                                <h1>For Patients</h1>
                                <div className='Section' style={{height:"100%"}}>
                                    <ul>
                                        <li>Record medications seamlessly using CAMS stickers</li>
                                        <li>Record attacks and understand your triggers</li>
                                        <li>Digitalise your asthma action plan</li>
                                    </ul>
                                    <a href={"/solution"} className="Link-accent">Learn more</a>
                                    <div className='Landing-row flex-horizontal' style={{paddingTop:"10"}}>
                                        <img src={mobileApp2} style={{marginLeft:-10,width:"50%", padding:"0"}}/>
                                        <img src={mobileApp3} style={{marginLeft:10,marginRight:-10,width:"50%", padding:"0"}}/>
                                    </div>
                                    <img src={googlePlayLogo} style={{width:"25%"}}/>
                                    <img src={appStoreLogo} style={{width:"22.5%"}}/>

                                </div>
                            </div>
                            <div className='Landing-col'>
                                <h2>Web App</h2>
                                <h1>For Doctors</h1>
                                <div className='Section' style={{height:"100%"}}>
                                    <ul>
                                        <li>Track your patient's data digitally, view by month or day</li>
                                        <li>GPs can save generated reports on their electronic medical records</li>
                                    </ul>
                                    <Link to="/signup" onClick={() => this.props.changeSelectedTab(0)}><button className='Button-selected'>Sign Up as a CAMS Doctor</button></Link>
                                    <img src={webApp1}/>                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer/>

                    
                    {/* </Link> */}
                    {/* <GoogleLogin
                        clientId="142220352368-1hsna03pomc59rkae9akkhggcr3hkafg.apps.googleusercontent.com"
                        buttonText="Login with Google"
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        redirectUri="/dashboard"
                    /> */}
                    {/* <button onClick={this.props.toggleLogout}>Logout</button> */}
                </div>
            </div>
        );
    }
}
export default LandingPage;