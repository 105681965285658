import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import '../App.css';
import '../styles/Dashboard.css';
import SessionCard from '../components/SessionCard.js';
import download from '../media/iconDownload.png'
import expand from '../media/iconExpand.png'
import report from '../media/dummyReport.png'
import { getAllSessions, getSummaryData, toggleSessionStar, getPdfData } from '../config/FirebaseConfig';

const Dashboard = (props) => {

    const [selectedSession, setSelectedSession] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [todaySessions, setTodaySessions] = useState([]);
    const [pastSessions, setPastSessions] = useState([]);
    const [selectedSessionSummary, setSelectedSessionSummary] = useState(null);

    

    const downloadPdf = async (selectedSession) => {
        // await getPdfData(selectedSession);
    }

    useEffect( async () => {
        console.log("use effect");
        setIsLoading(true);
        getSessions();
    }, []);

    const getSessions = async () => {
        // setIsLoading(true);
        const {valid, expired} = await getAllSessions();
        console.log(valid);
        console.log(expired);
        setTodaySessions(valid);
        setPastSessions(expired);
        setIsLoading(false);
    }

    const selectSession = async (selectedSessionUID) => {
        console.log("selected "+selectedSessionUID);
        if (selectedSession !== selectedSessionUID) { 
            setSelectedSession(selectedSessionUID);
            const summary = await getSummaryData(selectedSessionUID);
            setSelectedSessionSummary(summary ?? null);
            console.log(selectedSessionSummary);
        };
    };

    const toggleStar = async (sessionUID, newStarVal, expired=false) => { 
        console.log("toggleStar "+sessionUID);
        const res = await toggleSessionStar(sessionUID,newStarVal);
        let setter = (expired) ? setPastSessions : setTodaySessions;
        setter(existingItems => {
            return existingItems.map((entry) => {
                console.log(entry);
                if (entry._id.toString() === sessionUID.toString()) {
                    console.log("found it");
                    entry.starred = newStarVal;
                } else {
                    console.log(entry._id);
                }
                return entry;
            });
        });        
    };

    const cardify = (entries, expired=false) => {
        if (entries.length == 0) {
            return <p style={{padding: "5px 10px"}}>Nothing here!</p>;
        };
        return entries.map((entry) => {
            // console.log(entry);
            return <SessionCard sessionUID={entry._id.toString()} expired={expired} details={entry} selectSession={selectSession} isSelected={selectedSession===entry._id.toString()} toggleStar={toggleStar}/>;
        });
    };

    const generateSummary = (summary) => {
        // console.log(summary);
        return <div className='Section-preview-data'>
            <div>
                <h3> {summary.preventorAdherence} </h3>
                <p> Preventer Use Adherance </p>
            </div>
            <div>
                <h3> {summary.relieverCount} </h3>
                <p> Reliever Use </p>
            </div>
            <div>
                <h3> {summary.attackCount} </h3>
                <p> Attack </p>
            </div>
            <div>
                <h3> {(summary.latestACQ !== null) ? summary.latestACQ.score : "-"} </h3>
                <p> ACQ Score </p>
            </div>
            
            <div>
                <h3> {(summary.latestPeakFlow !== null) ? summary.latestPeakFlow.score : "-"} </h3>
                <p> Peak Flow Reading </p>
            </div>
            
        </div>
    }


    
    return (
        <div className='App-content'>
            <Helmet htmlAttributes>
                <html lang="en" />
                <title>CAMS Web App</title>
                <meta charset="utf-8"/>
            </Helmet>
            <div className='App-content-wrapper add-pad'>
                <div className='Dashboard'>
                    <div className='column-left'>
                        <div className='Section'>
                            <h1 className='inline'>Shared Today</h1>
                            <Link to="/qr"><button>Add a Patient</button></Link>
                            {isLoading ? (<p>Sessions Loading...</p>) 
                            : (<div class="cards-container">{cardify(todaySessions)}</div>)}
                        </div>

                        <div className='Section'>
                            <h1>Past Sessions</h1>
                            {isLoading ? (<p>Sessions Loading...</p>) 
                            : (<div class="cards-container">{cardify(pastSessions, true)}</div>)}
                        </div>
                    </div>

                    <div className='Section column-right Section-preview'>
                        <div className='Section-preview-header'>
                            <span style={{height:"40px",display:"inline-block"}}></span>
                            {(selectedSession == null || selectedSessionSummary === null) ? null : <Link to={(selectedSession) ? "/session/"+selectedSession.toString() : "#"}>
                                <div className='Section-preview-button'><img src={expand}/></div>
                            </Link>}
                            {(selectedSession == null) ? null : <div className='Section-preview-button'><img src={download} onClick={()=> downloadPdf(selectedSession)}/></div>}
                        </div>
                        <div className='Section-preview-content'>
                            {(selectedSession == null) 
                                ? <p className='Section-preview-empty'>Select a session to preview data here!</p>
                                : (selectedSessionSummary == null) 
                                    ? <img className='Section-preview-report' src={report}/>
                                    : <div> {generateSummary(selectedSessionSummary)} </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Dashboard;
