import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams, useParams, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { jsPDF } from 'jspdf';

import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css"
import { getTimeConcat } from "../functions/DateString.js"

import '../styles/SessionPage.css';
import star from "../media/iconStarSolid.png"
import starHollow from "../media/iconStarHollow.png"
import loading from '../media/loading.gif';

import download from '../media/iconDownload.png'
import toggleMonth from '../media/toggleMonth.png'
import toggleYear from '../media/toggleYear.png'

import { getAllSessions, getSessionData, toggleSessionStar } from '../config/FirebaseConfig';
import NotFoundPage from './NotFoundPage';
import SessionDataEntry from '../components/SessionDataEntry';
import CalendarPopup from '../components/CalendarPopup.js';

// import NotFoundPage
const SessionPage = (props) => {

    let { sessionId }  = useParams();
    sessionId = sessionId.toString();
    console.log(sessionId);

    const [isLoading, setIsLoading] = useState(true); 
    const [sessionData, setSessionData] = useState({}); 
    const [sessionEntry, setSessionEntry] = useState({}); 
    const [records, setRecords] = useState([]); 
    const [filteredRecords, setFilteredRecords] = useState([]); 
    const [filters, setFilters] = useState({
        asthma: true, 
        preventer: true,
        reliever: true,
        oral: true,
        acq: true,
        peak: true,
    }); 
    const localizer = momentLocalizer(moment);
    const [date, setDate] = useState(new Date());
    const [view, setView] = useState(Views.MONTH);

    const onNavigate = useCallback((newDate) => setDate(newDate), [setDate])
    const onView = useCallback((newView) => setView(newView), [setView])
    const downloadPdf = () => {
        console.log('donwload pdf');
        const doc = new jsPDF();
        doc.text('Hello world!');
        doc.save('test.pdf');
    }

    useEffect(() => {
        let filtered = filterRecords(records);
        console.log(filtered);
    }, [filters]);
    useEffect(() => {
        console.log("Refreshing calendar");
        console.log(filteredRecords);
    }, [filteredRecords]);
    useEffect( async () => {
        console.log("use effect");
        setIsLoading(true);
        const {valid} = await getAllSessions();
        let userHasAccess = false;
        valid.map((entry) => {
            // console.log(entry);
            if (entry._id.toString() === sessionId) {
                console.log("found it");
                console.log(entry);
                setSessionEntry(entry);
                userHasAccess = true;
            } 
        });
        if (userHasAccess) {
            await fetchData();
        } // returns page not found instead when sessionData is empty
        setIsLoading(false);
        
    }, []);

    const fetchData = async () => {
        const res = await getSessionData(sessionId);
        console.log(res);
        setSessionData(res);
        let complied = compileRecords(res);
        console.log(complied);
        let filtered = filterRecords(complied);
        console.log(filtered);
    }

    const filterRecords = (compiled) => {
        // console.log(records);
        let filtered = [];
        for (let category in compiled) {
            console.log(category, filters[category]);
            if (filters[category]) {
                console.log("added",category,compiled[category].length);
                filtered = filtered.concat(compiled[category]);
            } else {
                console.log("did not add",category);
            }
            // console.log(category, records[category]);
            // filtered = filtered.concat(complied[category]);
        }
        
        setFilteredRecords(filtered);
        console.log(filtered);
        return filtered;
    }

    const compileRecords = (data) => {
        let acq = data.acq.map((entry) => {
            return (
                {
                    id: entry._id, 
                    title: <CalendarPopup key={entry._id} content={entry} category={"acq"}/>,
                    start: moment(entry.dateCreated).toDate(), 
                    end: moment(entry.dateCreated).toDate(),
                    color: "transparent", 
                    allDay: false
                }
            )
        })
        let asthma = data.asthmaRecords.map((entry) => {
            return (
                {
                    id: entry._id, 
                    title: <CalendarPopup key={entry._id} content={entry} category={"attack"}/>,
                    start: moment(entry.date).toDate(), 
                    end: moment(entry.date).toDate(),
                    color: "transparent", 
                    allDay: false
                }
            )
        })
        let reliever = [];
        let preventer = [];
        let oral = [];
        let medication = data.medicationRecords.map((entry) => {
            let entryObj = {
                id: entry._id, 
                title: <CalendarPopup key={entry._id} content={entry} category={entry.category}/>,
                start: moment(entry.date).toDate(), 
                end: moment(entry.date).toDate(),
                color: "transparent", 
                allDay: false,
                moreInfo: entry,
            }
            switch (entry.category) {
                case "reliever": 
                    reliever.push(entryObj);
                    break;
                case "preventer": 
                    preventer.push(entryObj);
                    break;
                case "oral": 
                    oral.push(entryObj);
                    break;
            }
        })
        let peak = data.peakFlows.map((entry) => {
            return (
                {
                    id: entry._id, 
                    title: <CalendarPopup key={entry._id} content={entry} category={"peak"}/>,
                    start: moment(entry.dateCreated).toDate(), 
                    end: moment(entry.dateCreated).toDate(),
                    color: "transparent", 
                    allDay: false
                }
            )
        });
        let rec = {
            asthma: asthma, 
            preventer: preventer,
            reliever: reliever,
            oral: oral,
            acq: acq,
            peak: peak,
        };
        setRecords(rec);
        console.log(rec);
        return rec;
        filterRecords();
        setRecords(asthma.concat(medication).concat(acq).concat(peak));
    }

    const toggleStar = async (sessionUID, newStarVal) => { 
        console.log("toggleStar "+sessionUID);
        const res = await toggleSessionStar(sessionUID,newStarVal);
        setSessionEntry(prevState => {
            return {
                ...prevState,
                starred: newStarVal,
            };
        });        
    };

    const toggleFilter = (filter) => {
        console.log("toggleFilter", filter);
        setFilters(prevState => {
            let prevFilter = prevState[filter];
            return {
                ...prevState,
                [filter]: !prevFilter,
            };
        })
        filterRecords(records);
    }

    const displayFilters = () => {
        return (<div style={{width:"max-content", display:"inline-block", alignSelf:"center"}}>
            <span>Filters:</span>
            <button className='event-filter' onClick={()=>toggleFilter("preventer")} style={{backgroundColor:(filters["preventer"])?"var(--preventer)":"transparent",borderColor:"var(--preventer)"}}>Preventer</button>
            <button className='event-filter' onClick={()=>toggleFilter("reliever")} style={{backgroundColor:(filters["reliever"])?"var(--reliever)":"transparent",borderColor:"var(--reliever)"}}>Reliever</button>
            <button className='event-filter' onClick={()=>toggleFilter("oral")} style={{backgroundColor:(filters["oral"])?"var(--oral)":"transparent",borderColor:"var(--oral)"}}>Oral</button>
            <button className='event-filter' onClick={()=>toggleFilter("asthma")} style={{backgroundColor:(filters["asthma"])?"var(--attack)":"transparent",borderColor:"var(--attack)"}}>Attacks</button>
            <button className='event-filter' onClick={()=>toggleFilter("peak")} style={{backgroundColor:(filters["peak"])?"var(--peak)":"transparent",borderColor:"var(--peak)"}}>Peak Flow</button>
            <button className='event-filter' onClick={()=>toggleFilter("acq")} style={{backgroundColor:(filters["acq"])?"var(--acq)":"transparent",borderColor:"var(--acq)"}}>ACQ</button>
        </div>)
    }
    
    if (Object.keys(records).length === 0) {
        if (isLoading) {
            return (
                <div className='App-content'>
                    <div className="Landing-section align-center">
                        <img className='loading-icon' src={loading}/>
                    </div>
                </div>
            )
        } else {
            return <NotFoundPage />; 
        }
        
    }; 

    return (
        <div className='App-content no-scroll'>
            <Helmet htmlAttributes>
                <html lang="en" />
                <title>CAMS Web App</title>
                <meta charset="utf-8"/>
            </Helmet>
            <div className='App-content-wrapper add-pad' style={{maxWidth: "100vw"}}>
                <div className='Session-header'> 

                    <div className='Session-header-left'> 
                        <h1 className="inline"> {sessionEntry.patientName} </h1>
                        <span className='Session-star-wrapper' style={{padding: "0 10px"}}>
                            {sessionEntry.starred 
                                ? <img src={star} className="Star-icon Session-star" alt="star" onClick={() => toggleStar(sessionId, false)}/>
                                : <img src={starHollow} className="Star-icon Session-star Session-star-hollow Session-star-hollow-visible" alt="star" onClick={() => toggleStar(sessionId, true)} />
                            }
                            {sessionEntry.starred 
                                ? <span class="tooltiptext">Remove flag</span>
                                : <span class="tooltiptext">Flag session</span>
                            }
                        </span>
                        
                        {(sessionEntry.dateCreated) ? <p className="inline"> Data shared {sessionEntry.dateCreated.toDateString()} - {sessionEntry.endDate.toDateString()} </p> : null}
                    </div> 

                    <div style={{display:"flex",justifyContent:"space-between"}}> 
                        {displayFilters()}
                        <div style={{width:"max-content", display:"inline-block"}}>
                            <div className='Session-download-button'><img src={download} onClick={() => downloadPdf()}/></div>
                            <Link to={"/aap/"+sessionEntry.patientId.toString()}>
                                <button className='Button-selected'>Edit AAP</button>
                            </Link>
                        </div>
                        {/* <img className='Session-download-button' src={download}/>
                        <div className='Toggle Toggle-period'> 
                            <button className={isMonthView?"Button-selected":""} onClick={() => setIsMonthView(true)}> Month </button>
                            <button className={isMonthView?"":"Button-selected"} onClick={() => setIsMonthView(false)}> Year </button>
                            <button className={isMonth?"Button-selected":""} onClick={() => this.togglePeriod(true)}> Month </button>
                            <button onClick={() => this.togglePeriod(false)}> Year </button>
                        </div> 
                        <div className='Toggle Toggle-type'> 
                            <button className={graphView?"Button-selected":""} onClick={this.setGraph}> Graph </button>
                            <button className={graphView?"":"Button-selected"} onClick={this.setCalendar}> Calendar </button>
                        </div> */}
                    </div> 
                    

                </div> 
                <div className='calendar-wrapper'> 
                    <Calendar
                        // showAllEvents={true}
                        // style={{height:"100%"}}
                        // popup={true}
                        localizer={localizer}
                        events={filteredRecords}
                        startAccessor="start"
                        endAccessor="end"
                        defaultDate={moment().toDate()}
                        views={["month","week"]}
                        // onSelectEvent={(slotInfo) => {
                        //     console.log(slotInfo);
                        // }}
                        // selectable
                        onShowMore={(events,date)=>{
                            setDate(date);
                            setView(Views.WEEK);
                        }}
                        eventPropGetter={event => {
                            const eventData = filteredRecords.find(ot => ot.id === event.id);
                            const backgroundColor = eventData && eventData.color;
                            return { style: { backgroundColor } };
                        }}
                        date={date}
                        onNavigate={onNavigate}
                        onView={onView}
                        view={view}
                    />
                </div>
                <div style={{height:"30px"}}></div>

            </div>
        </div>
    );
    
}
export default SessionPage;
