import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCk6_u9Ii0Xs9D_40Jbu2J8M43T9psKDGk",
    authDomain: "cams-ed43f.firebaseapp.com",
    projectId: "cams-ed43f",
    storageBucket: "cams-ed43f.appspot.com",
    messagingSenderId: "142220352368",
    appId: "1:142220352368:web:b8b9bc156410baf6dd0db5",
    measurementId: "G-X623HK4XXY"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    // console.log(res);
    const user = res.user;
    console.log(user);
    const displayName = user.displayName;
    const accessToken = user.accessToken;
    const displayPic = user.photoURL;
    // console.log("displayName",displayName);
    // console.log("accessToken",accessToken);
    // const q = query(collection(db, "users"), where("uid", "==", user.uid));
    // const docs = await getDocs(q);
    // if (docs.docs.length === 0) {
    //   await addDoc(collection(db, "users"), {
    //     uid: user.uid,
    //     name: user.displayName,
    //     authProvider: "google",
    //     email: user.email,
    //   });
    // }
    const details = {
      displayName: displayName,
      accessToken: accessToken,
      displayPic: displayPic,
    }
    // console.log(details);
    return details;
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};

// const logout = () => {
//     signOut(auth);
// };

export {
    auth,
    signInWithGoogle,
    // logout
  };