import React from 'react';
import "../styles/InfoCard.css"

class InfoCard extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='info-card-container flex-vertical'>
                <div> 
                    <h3>{this.props.title}</h3>
                    <p>{this.props.details}</p>
                </div>
                <img src={this.props.img}/>
            </div>
        )
    }

}
export default InfoCard