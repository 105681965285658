import { useContext, createContext, useState } from "react";
import { isLoggedIn, checkValidUser, checkValidAdmin } from "../config/FirebaseConfig";
import { getValidUser, getIsAdmin } from "../functions/LocalStorage";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [login, setLogin] = useState(isLoggedIn());
  const [isValid, setValid] = useState(getValidUser());
  const [isAdmin, setAdmin] = useState(getIsAdmin());

  console.log(login, isValid, isAdmin);

  return (
    <AuthContext.Provider value={{ login, isValid, isAdmin, setLogin, setValid, setAdmin }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContext;
