import React from 'react';
import "../styles/InfoCard.css"
import tick from "../media/iconTick.png"
import cross from "../media/iconCross.png"
import { getDateString, getTimeConcat } from '../functions/DateString';

class SessionDataEntry extends React.Component {

    constructor(props) {
        super(props);

        // this.color = null; 
        switch (this.props.type) {
            case "asthma": 
                this.color = "#FFAAA9"; // red
                this.title = "Attack";
                this.body = (
                    <div>
                        <p>{this.props.details.triggers}</p>
                        <p>{this.props.details.notes}</p>
                    </div>);
                break;
            case "reliever": 
                this.color = "#96E6FF"; // blue
                this.title = "Reliever";
                this.body = (
                    <div>
                        <p>Dose: {this.props.details.doseValue}</p>
                    </div>);
                break;
            case "preventer": 
                this.color = "#FFD17C"; // yellow
                this.title = "Preventer";
                this.body = (
                    <div>
                        <p>Dose: {this.props.details.doseValue}</p>
                    </div>);
                break;
            case "acq": 
                this.color = "#F0BEFF"; // purp
                break;
            default: // peak flow
                this.color = "#D1DEFF" // grey 
                break; 
        }

        this.time = getTimeConcat(this.props.details.date); //this.props.details.date.toLocaleTimeString();

    }

    render() {
        return (
            <div className='info-card-container flex-horizontal session-data-entry-wrapper' key={this.props.details._partition}>
                <h3 className='session-data-entry-time'>{this.time}</h3>

                <div className='session-data-entry' style={{backgroundColor: this.color}}> 
                    <h3>{this.title}</h3>
                    {this.body}
                    {/* <p>{this.props.details.date.toString()}</p> */}
                    
                </div>
                        
            </div>
        )
    }

}
export default SessionDataEntry