import React, { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import { getQRCode } from '../config/FirebaseConfig';
import loading from '../media/loading.gif';

const QRPage = () => {
    const [QRstring, setQRstring] = useState("");

    useEffect( async () => {
        const qr = await getQRCode();
        setQRstring(qr);
    }, [])

    return (
        <div className='App-content'>
            <div className='App-content-wrapper add-pad'>
                <h1>Add a Patient</h1>
                <p>To access your patient's asthma data, get them to scan the QR code below with their mobile device.</p>
                <div style={{padding: "40px 0"}}> {(QRstring === "") 
                    ? <img className='loading-icon' src={loading}/>
                    : <QRCode value={QRstring} /*size={300}*/ />
                    }
                </div>
            </div>
        </div>
    );

}
export default QRPage;
