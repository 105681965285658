import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';

import mobileApp2 from "../media/demo/Android Progress Summary.png"
import mobileApp3 from "../media/demo/Iphone Medication Reminders.png"

import webApp1 from "../media/demo/Web calendar.jpg"
import FeaturesCarousel from "../components/FeaturesCarousel"
// import FeaturesCarousel from '../components/NewCarousel';
import Footer from './info/Footer';


const SolutionPage = (props) => {

    React.useEffect(() => {
        props.changeSelectedTab(2);
    }, []);
    
    return (
        <div className='App-content'>
            <Helmet htmlAttributes>
                <html lang="en" />
                <title>Our Solution | CAMS</title>
                <meta charset="utf-8"/>
            </Helmet>
            <div className='App-content-wrapper no-pad'>
                {/* <h1 className='Landing-heading-large'>Our Solution</h1>
                <h1>Features</h1> */}
                <div className='Landing-row-wrapper'>
                    <h1 className='Landing-heading-large'>Our Solutions</h1>
                    <div className='Landing-row flex-wrap'>
                        <div className='Landing-col'>
                            <h2>{"Android & iOS App"}</h2>
                            <h1>For Patients</h1>
                            <div className='Landing-row flex-horizontal' style={{paddingTop:"0"}}>
                                <img src={mobileApp2} style={{marginLeft:-10,width:"50%", padding:"0"}}/>
                                <img src={mobileApp3} style={{marginLeft:10,marginRight:-10,width:"50%", padding:"0"}}/>
                            </div>
                        </div>
                        <div className='Landing-col'>
                            <h2>Web App</h2>
                            <h1>For Doctors</h1>
                            <Link to="/signup" onClick={() => this.props.changeSelectedTab(0)}><button className='Button-selected'>Sign Up as a CAMS Doctor</button></Link>
                            <img src={webApp1}/>
                        </div>
                    </div>
                    <div className='Landing-row'>
                        <h1>Features</h1>
                    </div>
                    <div className='Landing-section' style={{height:"300px"}}>
                        <FeaturesCarousel/>
                    </div>
                    <div className='Landing-row-wrapper accent-row'>
                        <h1 className='Landing-heading-large align-center'>Are you a Doctor?</h1> 
                        <div className='Landing-row'>
                            <p>Join us in revolutionising Singapore's healthcare industry and provide your asthma patients with better support</p>
                        </div>    
                        {/* <div className='Landing-row'> */}
                            <Link to="/signup" onClick={() => this.props.changeSelectedTab(0)}><button className='Button-white'>Sign Up as a CAMS Doctor</button></Link>
                        {/* </div> */}
                        <div className='Landing-row'></div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    )

}

export default SolutionPage;