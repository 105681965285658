import React, { Component } from 'react';

const SettingsPage = () => {

    return (
        <div className='App-content'>
            <h1>Settings</h1>
        </div>
    );

}
export default SettingsPage;
