import "./App.css";
import React, { useState, useEffect } from "react";
import { auth, isLoggedIn } from "./config/FirebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { AuthProvider } from "./context/AuthProvider";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMediaQuery } from 'react-responsive'

import Logo from "./media/Logo.png";

import RequireAuth from "./components/RequireAuth";
import Navbar from "./components/Navbar";
import SessionPage from "./pages/SessionPage";
import AAPPage from "./pages/AAPPage";
import Dashboard from "./pages/Dashboard";
import LandingPage from "./pages/LandingPage";
import SettingsPage from "./pages/SettingsPage";

import AboutPage from "./pages/AboutPage";
import SolutionPage from "./pages/SolutionPage";
import BlogPage from "./pages/BlogPage";
import ContactPage from "./pages/ContactPage";
import ContactSuccessPage from "./pages/ContactSuccessPage";

import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import SuccessPage from "./pages/SuccessPage";
import VerifyPage from "./pages/VerifyPage";
import QuizInitiatePage from "./pages/QuizInitiatePage";
import AdminPage from "./pages/AdminPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import ProfilePage from "./pages/ProfilePage";
import QRPage from "./pages/QRPage";
import NotFoundPage from "./pages/NotFoundPage";
import PrivacyPolicyPage from "./pages/info/PrivacyPolicyPage";
import TermsPage from "./pages/info/TermsPage";
import FeedbackPage from "./pages/FeedbackPage";
import FeedbackSuccessPage from "./pages/info/FeedbackSuccessPage";

const App = (props) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [login, setLogin] = useState(false);
  // const [dp, setDp] = useState();
  // const [config, setConfig] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const changeSelectedTab = (newTab) => {
    console.log("Current tab:", newTab);
    setSelectedTab(newTab);
  };
  let isMobile = useMediaQuery({ query: '(max-width: 800px)' });

  useEffect(() => {
    console.log("use effect used");
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    setLogin(isLoggedIn());
  }, []);

  const toggleLogin = (e) => {
    console.log("logging in");
    setLogin(true);
  };

  const toggleLogout = (e) => {
    console.log("Logging out");
    setLogin(false);
  };

  return (
    <AuthProvider>
      <div className="App">
        <Helmet htmlAttributes>
          <html lang="en" />
          <title>CAMS - Asthma Companion</title>
          {/* <link rel="icon" type="image/x-icon" href="'./media/Logo.png'"></link> */}
          <meta charset="utf-8" />
        </Helmet>

        <Router>
          <Navbar
            toggleLogin={toggleLogin}
            user={currentUser}
            // dp={dp}
            login={login}
            toggleLogout={toggleLogout}
            selectedTab={selectedTab}
            changeSelectedTab={changeSelectedTab}
            isMobile={isMobile}
          />
          <Routes>
            <Route
              exact
              path="/"
              element={
                <LandingPage
                  toggleLogin={toggleLogin}
                  selectedTab={selectedTab}
                  changeSelectedTab={changeSelectedTab}
                />
              }
            />
            <Route element={<RequireAuth />}>
              <Route path="/dashboard" element={<Dashboard />} />
            </Route>
            <Route
              exact
              path="/about"
              element={<AboutPage changeSelectedTab={changeSelectedTab} />}
            />
            <Route
              exact
              path="/solution"
              element={<SolutionPage changeSelectedTab={changeSelectedTab} />}
            />
            <Route
              exact
              path="/blog"
              element={<BlogPage changeSelectedTab={changeSelectedTab} />}
            />
            <Route
              exact
              path="/contact"
              element={<ContactPage changeSelectedTab={changeSelectedTab} />}
            />
            <Route
              exact
              path="/privacy-policy"
              element={<PrivacyPolicyPage changeSelectedTab={changeSelectedTab} />}
            />
            <Route
              exact
              path="/terms-conditions"
              element={<TermsPage changeSelectedTab={changeSelectedTab} />}
            />

            <Route element={<RequireAuth />}>
              <Route path="/session/:sessionId" element={<SessionPage />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route path="/aap/:patientId" element={<AAPPage />} />
            </Route>
            <Route
              exact
              path="/contact/success/"
              element={<ContactSuccessPage />}
            />
            <Route
              exact
              path="/feedback"
              element={<FeedbackPage changeSelectedTab={changeSelectedTab} />}
            />
            <Route
              exact
              path="/feedback/success/"
              element={<FeedbackSuccessPage />}
            />

            <Route element={<RequireAuth />}>
              <Route exact path="/profile" element={<ProfilePage />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route exact path="/qr" element={<QRPage />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route exact path="/settings" element={<SettingsPage />} />
            </Route>
            <Route
              exact
              path="/login"
              element={
                <LoginPage
                  toggleLogin={toggleLogin}
                  user={currentUser}
                  // dp={dp}
                  // login={login}
                  // toggleLogout={toggleLogout}
                />
              }
            />
            <Route exact path="/forgetpassword" element={<ForgetPasswordPage />} />
            <Route exact path="/signup" element={<SignupPage />} />
            <Route exact path="/signup/success/" element={<SuccessPage />} />
            <Route exact path="/signup/verify/" element={<VerifyPage />} />
            <Route
              exact
              path="/quiz/initiate"
              element={
                <QuizInitiatePage changeSelectedTab={changeSelectedTab} />
              }
            />
            <Route element={<RequireAuth />}> 
              <Route exact path="/admin" element={<AdminPage />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} /> {/* default route, page not found */}
          </Routes>
        </Router>
      </div>
    </AuthProvider>
  );
};

export default App;
