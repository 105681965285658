import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import DoctorCard from '../components/DoctorCard';
import { getAllDoctors, updateDoctorStatus } from '../config/FirebaseConfig';
import loading from '../media/loading.gif';

const AdminPage = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [pendingDoctors, setPendingDoctors] = useState([]);
    const [verifiedDoctors, setVerifiedDoctors] = useState([]);
    const [rejectedDoctors, setRejectedDoctors] = useState([]);

    useEffect(async () =>  {
        console.log("use effect");
        setIsLoading(true);
        await getDoctors();
    }, []);

    const getDoctors = async () => {
        // setIsLoading(true);
        const {verified, pending, rejected} = await getAllDoctors();
        console.log(verified);
        console.log(pending);
        console.log(rejected);
        setPendingDoctors(pending);
        setVerifiedDoctors(verified);
        setRejectedDoctors(rejected);
        setIsLoading(false);
    }

    const approve = async (id) => {
        console.log("approve "+id);
        await updateDoctorStatus(id, "verified");
        getDoctors();
    }
    const reject = async (id) => {
        console.log("reject "+id);
        await updateDoctorStatus(id, "rejected");
        getDoctors();
    }

    const cardify = (entries, status) => {
        return Object.values(entries).map((entry) => {
            // console.log(entry);
            return <DoctorCard details={entry} status={status} approve={approve} reject={reject}/>;
        });
    };

    const displayCount = (count) => {
        count = count ?? 0;
        // let count = (isVerified) ? verifiedDoctors.length : pendingDoctors.length;
        // console.log(count);
        return <p className='inline'>{"("+count+")"}</p>;
    }

    return (
        <div className='App-content'>
            <Helmet htmlAttributes>
                <html lang="en" />
                <title>CAMS Admin Page</title>
                <meta charset="utf-8"/>
            </Helmet>
            <div className='App-content-wrapper add-pad'>
                <div className='Dashboard'>
                    <div className='column-left'>
                        <div className='Section'>
                            <h1>Doctors Pending Verification {displayCount(pendingDoctors.length)}</h1>
                            {isLoading ? <img className='loading-icon' src={loading}/>
                            : (<div class="cards-container">{cardify(pendingDoctors,"pending")}</div>)}
                        </div>
                        <div className='Section'>
                            <h1>Rejected Doctors {displayCount(rejectedDoctors.length)}</h1>
                            {isLoading ? <img className='loading-icon' src={loading}/>
                            : (<div class="cards-container">{cardify(rejectedDoctors,"rejected")}</div>)}
                        </div>
                    </div>

                    <div className='column-left'>
                        <div className='Section'>
                            <h1>Verified Doctors {displayCount(verifiedDoctors.length)}</h1>
                            {isLoading ? <img className='loading-icon' src={loading}/>
                            : (<div class="cards-container">{cardify(verifiedDoctors,"verified")}</div>)}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}

export default AdminPage;