import React, { Component } from 'react'; 
import '../styles/ProfileDropdown.css';
import {Link} from 'react-router-dom';
import { logout } from "../config/FirebaseConfig";
import { getUsername } from '../functions/LocalStorage';

class ProfileDropdown extends Component {
  constructor(props) {
    super(props);
  }
  
  handleClick = () => {
    console.log("hovered out of dropdown");
    this.props.toggle(); 
  };

  handleLogout = () => {
    this.props.signout();
    this.props.toggle(); 
    logout();
  };

  userName = getUsername();

  render() {
    return ( 
      <div>
        <div id="profile-dropdown-background" onMouseEnter={this.handleClick}> </div>
        <div id="profile-dropdown"> 
          <p id="profile-dropdown-header" class="profile-dropdown-items"> Logged in as </p>
          <h3 id="profile-dropdown-username" class="profile-dropdown-items"> { this.userName!=null ? this.userName : "<Username>" }</h3>
          <hr/>
          <div id="profile-dropdown-content"> 
            {/* <Link to={`/user/${this.props.user.user_id}`} id="profile-dropdown-userpage" class="profile-dropdown-items"> Overview </Link>  */}
            <a href="/profile" class="profile-dropdown-items"> My Profile </a>
            <a href="/settings" class="profile-dropdown-items"> Settings </a>
            <a href="/" id="profile-dropdown-logout" onClick={this.handleLogout} class="profile-dropdown-items"> Logout </a>
          </div>
        </div>
      </div>
    )
  }
}

export default ProfileDropdown; 

