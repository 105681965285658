import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
// import { Carousel } from 'react-responsive-carousel';
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import { config } from "react-spring";
import InfoCard from "./InfoCard";

import mobileApp1 from "../media/mobileapp1.png"
import mobileApp2 from "../media/mobileapp2.jpg"
import recordMeds from "../media/demo/Record Meds.png"

export default class FeaturesCarousel extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: false, //true,
    // config: config.slow // gentle / slow / wobbly / stiff
  };

  slides = [
    {
      key: 1,
      content: <InfoCard 
        title="Understand your Asthma patterns" 
        details={<ul>
            <li>Easily record your medication intake</li>
            <li>Record asthma attacks including triggers and the location where you had an attack</li>
          </ul>}
        // img={recordMeds}
        />
    },
    {
      key: 2,
      content: <InfoCard 
        title="Receive Reminders" 
        details={<ul>
                  <li>Improve daily preventer use through in-app notifications</li>
                  <li>Set and receive reminders to take your medication</li>
                  <li>Reminders to refill your expired/finished medication</li>
                </ul>}
        />
    },
    {
      key: 3,
      content: <InfoCard 
        title="Digital Asthma Action Plan" 
        details={<p>Keep a digitalised copy of your asthma action plan</p>}
        />
    },
    {
      key: 4,
      content: <InfoCard 
        title="Digital Tracking" 
        details={<ul>
                  <li>View a summary of your monthly asthma attacks and medication intake</li>
                  <li>Visualise and understand medication or asthma events across the month or year</li>
                  <li>Share your report with a CAMS doctor</li>
                </ul>}
        />
    },
    {
      key: 5,
      content: <InfoCard 
        title="Learn More About Asthma" 
        details={<ul>
                  <li>Understand your top asthma triggers and know how to mitigate them</li>
                  <li>Discover how to better manage your asthma through videos</li>
                </ul>}
        />
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => this.setState({ goToSlide: index }) };
  });

  onChangeInput = e => {
    this.setState({
      [e.target.name]: parseInt(e.target.value, 10) || 0
    });
  };

  render() {
    return (
      <div style={{ width: "100%", height: "100%", margin: "0 auto"}}>

        <Carousel // autoPlay={true} showThumbs={false}>
          slides={this.slides}
          goToSlide={this.state.goToSlide}
          offsetRadius={this.state.offsetRadius}
          showNavigation={this.state.showNavigation}
          animationConfig={this.state.config}
          autoPlay={true}
        />
          
          {/* <div>
            <label>Go to slide: </label>
            <input name="goToSlide" onChange={this.onChangeInput} />
          </div>
          <div>
            <label>Offset Radius: </label>
            <input name="offsetRadius" onChange={this.onChangeInput} />
          </div>
           <div>
            <label>Show navigation: </label>
            <input
              type="checkbox"
              checked={this.state.showNavigation}
              name="showNavigation"
              onChange={e => {
                this.setState({ showNavigation: e.target.checked });
              }}
            />
          </div> */}
         
      </div>
    );
  }
}