import React, { Component } from 'react';
import '../styles/ContactPage.css';
import {Helmet} from "react-helmet";
import Footer from './info/Footer';

const ContactPage = (props) => {

    React.useEffect(() => {
        props.changeSelectedTab(4);
    }, []);
    
    return (
        <div className='App-content'>
            <Helmet htmlAttributes>
                <html lang="en" />
                <title>Contact Us | CAMS</title>
                <meta charset="utf-8"/>
            </Helmet>
            <div className='App-content-wrapper no-pad'>

                <div className='Landing-row-wrapper'>
                    <div className='Landing-section info-page'>
                        <h1 className='Landing-heading-large'>Drop Us a Message!</h1>
                        <p>Send us your details and enquiry here and we'll get back to you as soon as possible!</p>
                    </div>
                </div>

                <div className='Landing-row-wrapper'>
                <form 
                    id="contact-form"
                    name="contact" 
                    method='post' 
                    data-netlify-honeypot='bot-field'
                    className='Landing-section' 
                    action='/contact/success/'
                >
                    <input type="hidden" name="form-name" value="contact" />
                    <input name='bot-field' style={{display:"none"}}/>
                    <div className='Contact-left align-left'>
                    <p>
                        <label>Name<input required type="text" name="name" /></label>
                    </p>
                    <p>
                        <label>Email Address<input required type="email" name="email" /></label>
                    </p>
                    <p>
                        <label>Phone<input required type="tel" name="phone" /></label>
                    </p>
                    </div>
                    {/* <p>
                        <label>Your Role: <select name="role[]" multiple>
                        <option value="leader">Leader</option>
                        <option value="follower">Follower</option>
                        </select></label>
                    </p> */}
                    <div className='Contact-right align-left'>
                    <p>
                        <label>Message<textarea required name="message"></textarea></label>
                    </p>
                    <p>
                        <button id="Contact-submit" type="submit" className='Button-selected'>Send</button>
                    </p>
                    </div>
                    
                </form>
                </div>

                <div className='Landing-row-wrapper'>
                    <div className='Landing-section'>
                        <h1>Visit Us</h1> 
                        <p>658 Punggol East</p> 
                        <p>#01-04</p> 
                        <p>Singapore 820658</p>

                        <h1>Contact Us</h1>
                        <a href="mailto:cams.singapore@gmail.com" className='Link-accent'>cams.singapore@gmail.com</a>
                    </div>
                </div>

                <Footer/>

            </div>
        </div>
    )

}

export default ContactPage;