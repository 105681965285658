const ageOptions = ["0 - 4","5 - 11","≥ 12"];
const symptomsOptions = ["≤ 2 days/week","> 2 days/week but not daily","Daily","Throughout the day"];
const awakenOptions = ["0","1 - 2x/month","3 - 4x/month","≥ 1x/week but not nightly","Often 7x/week"];
const sabaOptions = ["≤ 2 days/week","> 2 days/week but not daily","> 2 days/week but not daily, and not more than once on any day","Daily","Several times per day"];
const interfereOptions = ["None","Minor limitation","Some limitation","Extremely limited"];
const lungOptions = ["> 80%, normal FEV₁ between exacerbations","> 80%","60-80%","< 60%"];
const exaOptions = ["0 - 1/year","≥ 2/year"];

function symptomsSeverity(symptoms) {
    let severity;
    switch (symptoms) {
        case symptomsOptions[1]:
            severity = 1; // mild
            break;
        case symptomsOptions[2]:
            severity = 2; // moderate
            break;
        case symptomsOptions[3]:
            severity = 3; // severe
            break;
        default:
            severity = 0; // intermittent
            break;
    }
    return severity;
}

function awakenSeverity(awaken, age) {
    let severity;
    if (age === ageOptions[0]) {
        switch (awaken) {
            case awakenOptions[1]:
                severity = 1; // mild
                break;
            case awakenOptions[2]:
                severity = 2; // moderate
                break;
            case awakenOptions[3]: 
            case awakenOptions[4]:
                severity = 3; // severe
                break;
            default:
                severity = 0; // intermittent
                break;
        }
    } else {
        switch (awaken) {
            case awakenOptions[2]:
                severity = 1; // mild
                break;
            case awakenOptions[3]:
                severity = 2; // moderate
                break;
            case awakenOptions[4]:
                severity = 3; // severe
                break;
            default:
                severity = 0; // intermittent
                break;
        }
    }
    return severity;
}

function sabaSeverity(saba, age) {
    let severity;
    switch (saba) {
        case sabaOptions[1]: // maybe switch options 1 and 2? cuz 1 is more severe than 2?
            if (age === ageOptions[0]) {
                severity = 1 // mild
            } else {
                severity = 2 // moderate
            }
            break;
        case sabaOptions[2]:
            severity = 1 // mild
            break;
        case sabaOptions[3]:
            severity = 2 // moderate
            break;
        case sabaOptions[4]:
            severity = 3 // severe
            break;
        default:
            severity = 0 // intermittent
            break;
    }
    return severity;
}

function interfereSeverity(interfere) {
    let severity;
    switch (interfere) {
        case interfereOptions[1]:
            severity = 1; // mild
            break;
        case interfereOptions[2]:
            severity = 2; // moderate
            break;
        case interfereOptions[3]:
            severity = 3; // severe
            break;
        default:
            severity = 0; // intermittent
            break;
    }
    return severity;
}

function lungSeverity(lung, age) {
    console.log(lung);
    let severity;
    if (age === ageOptions[0]) {
        severity = 0; // intermittent
    } else {
        switch (lung) {
            case lungOptions[1]:
                severity = 1; // mild
                break;
            case lungOptions[2]:
                severity = 2; // moderate
                break;
            case lungOptions[3]:
                severity = 3; // severe
                break;
            default:
                severity = 0; // intermittent
                break;
        }
    }
    return severity;
}

function exaSeverity(exa) {
    let severity;
    switch (exa) {
        case exaOptions[1]:
            severity = 1; // mild
            break;
        default:
            severity = 0; // intermittent
            break;
    }
    return severity;
}

function calcStep(severities, age) {
    console.log(severities);
    let highestSeverity = 0;
    for (const [key, value] of Object.entries(severities)) {
        if (value > highestSeverity) {
            highestSeverity = value;
        }
    }
    console.log(highestSeverity);

    let step; 
    switch (highestSeverity) {
        case 1: // mild
            step = "Step 2";
            break; 
        case 2: // moderate
            if (age === ageOptions[1]) {
                step = "Step 3 medium-dose ICS option";
            } else {
                step = "Step 3";
            }
            break; 
        case 3: // severe
            switch (age) {
                case ageOptions[1]: 
                    step = "Step 3 medium-dose ICS option or Step 4"; 
                    break;
                case ageOptions[2]: 
                    step = "Step 4 or 5"; 
                    break;
                default: 
                    step = "Step 3"; 
                    break;
            }
            break; 
        default: // intermittent
            step = "Step 1";
            break;
    }
    return step;
}
    

export {
    ageOptions, symptomsOptions, awakenOptions, sabaOptions, interfereOptions, lungOptions, exaOptions,
    calcStep, symptomsSeverity, awakenSeverity, sabaSeverity, interfereSeverity, lungSeverity, exaSeverity,
}